<template>
    <div class="d-flex container-fluid flex-column">
        <div class="information-top px-5 py-4 row">
            <div class="row mb-5">
                <div class="col text-center">
                    <h2>Productieregels</h2>
                </div>
            </div>
            <div id="counters" class="row d-flex justify-content-center">
                <div class="col-2 text-center">
                    <div class="counter">
                        {{ statusCounts.productionFileNotPrinted }}
                    </div>
                    <p>Geen productiebon</p>
                </div>
                <div class="col-2 text-center">
                    <div class="counter">
                        {{ statusCounts.productionFilePrinted }}
                    </div>
                    <p>Productiebon afgedrukt</p>
                </div>
                <div class="col-2 text-center">
                    <div class="counter">{{ statusCounts.printed }}</div>
                    <p>Geprint</p>
                </div>
                <div class="col-2 text-center">
                    <div class="counter">
                        {{ statusCounts.finishingOutsourced }}
                    </div>
                    <p>Externe afwerking</p>
                </div>
                <div class="col-2 text-center">
                    <div class="counter">{{ statusCounts.finishingDone }}</div>
                    <p>Afwerking klaar</p>
                </div>
            </div>
        </div>
        <div class="row info-back">
            <div class="text-start" v-on:click="$router.back()">
                <i class="fas fa-chevron-left p-3"></i>
            </div>
        </div>
        <div v-if="productionLines">
            <div class="accordion">
                <div
                    class="accordion-item"
                    v-for="(productionLine, index) in productionLines"
                    :key="productionLine.guid"
                >
                    <orderline
                        v-on:statusChanged="updateCounters"
                        :orderline="productionLine"
                        :index="index"
                        :totalOrderlines="productionLines.length"
                        :isOrderDetail="false"
                        :isProductionDetail="true"
                        v-on:orderlineUpdated="updateOrderline"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Orderline from "@/components/order/Orderline.vue";
export default {
    name: "Production",
    props: [],
    components: {
        Orderline,
    },
    data() {
        return {
            productionLines: [],
            statusCounts: {},
        };
    },
    async beforeMount() {
        await this.getProductionLines().then((result) => {
            this.productionLines = result;
        });
        this.setStatusCounts();
    },

    methods: {
        setStatusCounts() {
            this.statusCounts = {
                productionFileNotPrinted: 0,
                productionFilePrinted: 0,
                printed: 0,
                finishingOutsourced: 0,
                finishingDone: 0,
            };
            for (let productionLine of this.productionLines) {
                productionLine.statusCheck = {};
                productionLine.statusCheck.productionFilePrinted = 0;
                productionLine.statusCheck.printed = 0;
                productionLine.statusCheck.finishingOutsourced = 0;
                productionLine.statusCheck.handlingFinished = 0;

                for (let status of productionLine.statuses) {
                    if (status.status == "Productiebon geprint")
                        productionLine.statusCheck.productionFilePrinted++;
                    if (status.status == "Geprint")
                        productionLine.statusCheck.printed++;
                    if (status.status == "Niet geprint")
                        productionLine.statusCheck.printed--;
                    if (status.status == "Afwerking uitbesteed")
                        productionLine.statusCheck.finishingOutsourced++;
                    if (status.status == "Afwerking voltooid")
                        productionLine.statusCheck.handlingFinished++;
                    if (status.status == "Afwerking niet voltooid")
                        productionLine.statusCheck.handlingFinished--;
                }

                if (productionLine.statusCheck.productionFilePrinted)
                    this.statusCounts.productionFilePrinted++;
                if (!productionLine.statusCheck.productionFilePrinted)
                    this.statusCounts.productionFileNotPrinted++;
                if (productionLine.statusCheck.printed)
                    this.statusCounts.printed++;
                if (productionLine.statusCheck.notPrinted)
                    this.statusCounts.printed++;
                if (productionLine.statusCheck.finishingOutsourced)
                    this.statusCounts.finishingOutsourced++;
                if (productionLine.statusCheck.handlingFinished)
                    this.statusCounts.finishingDone++;
            }
        },
        updateCounters(status, orderline) {
            if (status == "productionFilePrinted") {
                this.statusCheck(orderline, "productionFilePrinted", 1, () => {
                    this.statusCounts.productionFilePrinted++;
                    this.statusCounts.productionFileNotPrinted--;
                });
            } else if (status == "printed") {
                this.statusCheck(orderline, "printed", 1, () => {
                    this.statusCounts.printed++;
                });
            } else if (status == "notPrinted") {
                this.statusCheck(orderline, "printed", -1, () => {
                    this.statusCounts.printed--;
                });
            } else if (status == "finishingOutsourced") {
                this.statusCheck(orderline, "printed", 0, () => {
                    this.statusCounts.finishingOutsourced++;
                });
            } else if (status == "handlingFinished") {
                this.statusCheck(orderline, "handlingFinished", 1, () => {
                    this.statusCounts.finishingDone++;
                });
            } else if (status == "handlingNotFinished") {
                this.statusCheck(orderline, "handlingFinished", -1, () => {
                    this.statusCounts.finishingDone--;
                });
            }
        },
        statusCheck(orderline, status, changeVal, fn) {
            for (let productionLine of this.productionLines) {
                if (productionLine.guid == orderline.guid) {
                    productionLine.statusCheck[status] += changeVal;
                    fn();
                }
            }
        },
    },
};
</script>

<style scoped>
html {
    overflow-y: scroll;
}
#order-list {
    min-height: 100vh;
}
.moon-loader-container {
    position: relative;
    bottom: 80vh;
}
.counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    font-size: 1.5em !important;
    background-color: #131315;
    color: white;
    font-weight: 800;
    font-size: 1em;
}
#counters .col-2 {
    display: flex;
    text-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
#counters .col-2 p {
    margin-top: 1em;
    font-size: 1.3em;
    font-weight: 800;
}
</style>
