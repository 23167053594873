import { createRouter, createWebHistory } from 'vue-router'

// Begin page
import Dashboard from '@/views/Dashboard.vue'

// File pages
import CheckOpen from '@/views/file/check/CheckOpen.vue';
import CheckReviewed from '@/views/file/check/CheckReviewed.vue';
import FileDetail from '@/views/file/FileDetail.vue'
import FileCreate from '@/views/file/FileCreate.vue'

// Customer pages
import Customers from '@/views/customer/Customer.vue'
import NewCustomer from '@/views/customer/NewCustomer.vue'
import CustomerDetail from '@/views/customer/CustomerDetail.vue'

// Order pages
import Order from '@/views/order/Order.vue'
import OrderDetail from '@/views/order/OrderDetail.vue'
import OrderOpen from '@/views/order/OrderOpen.vue'

// Production pages
import Production from '@/views/production/Production.vue'

// Auth redirect
import Callback from '@/views/auth/Callback'
import ErrorPage from '@/views/auth/Error'

// Auth check
import { routeGuard } from '@/auth'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/file/check/open',
    name: 'CheckOpen',
    component: CheckOpen,
  },
  {
    path: '/file/check/reviewed',
    name: 'CheckReviewed',
    component: CheckReviewed,
  },
  {
    path: '/file/:id',
    name: 'FileDetail',
    component: FileDetail,
  },
  {
    path: '/file/create',
    name: 'FileCreate',
    component: FileCreate,
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
  },
  {
    path: '/customers/create',
    name: 'NewCustomer',
    component: NewCustomer,
  },
  {
    path: '/customer/:guid',
    name: 'CustomerDetail',
    component: CustomerDetail,
    props: true
  },
  {
    path: '/orders',
    name: 'Order',
    component: Order,
    props: {beginPage: 1}
  },
  {
    path: '/orders/:guid',
    name: 'OrderDetail',
    component: OrderDetail,
  },
  {
    path: '/orders/open',
    name: 'OrderOpen',
    component: OrderOpen
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/production',
    name: 'Production',
    component: Production
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  routeGuard(to, from, next);
});

export default router