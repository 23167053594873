<template>
  <div class="top-nav">
    <div>
      <!-- Check that the SDK client is not currently loading before accessing is methods -->
      <div class="text-end" v-if="!$auth.loading.value">
        <!-- show login when not authenticated -->
        <button
          class="btn btn-primary py-3"
          v-if="!$auth.isAuthenticated.value"
          @click="login"
        >
          Log in
        </button>
        <!-- show logout when authenticated -->
        <button
          class="btn btn-primary py-3"
          v-if="$auth.isAuthenticated.value"
          @click="logout"
        >
          Log out
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopbarMenu",
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style>
.top-nav {
  background-color: #222;
  position: absolute;
  width: 100%;
  left: 0px;
}
</style>