import pdfjsLib from "pdfjs-dist/build/pdf.js";
import axios from "axios";
import dateFormat, { i18n } from "dateformat";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { servicesAxios, webApiAxios } from "@/config";
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

console.log("INDEX.JS LOADED");

// const storedAuthData = localStorage.getItem("@@auth0spajs@@::WA92EgVmI07HI5fEAZXv8DQ4sriDgv0Q::organizer::openid profile email offline_access");
// console.log("stored auth data = ", storedAuthData);
// if (storedAuthData) {
//    alert("adding bearer");
//    alert(storedAuthData);
//    alert(JSON.parse(storedAuthData).body.access_token)

//    webApiAxios.interceptors.request.use(function (config) {
//       alert("request Interceptor for web api");
//       config.headers.Authorization = "Bearer " + JSON.parse(storedAuthData).body.access_token;
//       return config;
//    }, function (error) {
//       alert("error");
//       return error;
//    });
//    servicesAxios.interceptors.request.use(function (config) {
//       alert("Request interceptor for services");
//       config.headers.Authorization = "Bearer " + JSON.parse(storedAuthData).body.access_token;
//       return config;
//    }, function (error) {
//       alert("error fvndaof");
//       return error;
//    });

//    // webApiAxios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(storedAuthData).body.access_token;
//    // servicesAxios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(storedAuthData).body.access_token;
// }

Date.prototype.addWeekDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    let dayOfWeek = date.getDay();
    if (dayOfWeek == 6) date.setDate(date.getDate() + 2);
    if (dayOfWeek == 0) date.setDate(date.getDate() + 1);
    return date;
};

export default {
    methods: {
        generateGUID() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0)
                    .toString(16)
                    .substring(1);
            }
            return (
                S4() +
                S4() +
                "-" +
                S4() +
                "-4" +
                S4().substr(0, 3) +
                "-" +
                S4() +
                "-" +
                S4() +
                S4() +
                S4()
            ).toLowerCase();
        },
        // Restricts input for the given textbox to the given inputFilter function.
        setInputFilter(textbox, inputFilter) {
            [
                "input",
                "keydown",
                "keyup",
                "mousedown",
                "mouseup",
                "select",
                "contextmenu",
                "drop",
            ].forEach(function(event) {
                textbox.addEventListener(event, function() {
                    if (inputFilter(this.value)) {
                        this.oldValue = this.value;
                        this.oldSelectionStart = this.selectionStart;
                        this.oldSelectionEnd = this.selectionEnd;
                    } else if (this["oldValue"]) {
                        // eslint-disable-line
                        // eslint-disable-line
                        this.value = this.oldValue;
                        this.setSelectionRange(
                            this.oldSelectionStart,
                            this.oldSelectionEnd
                        );
                    } else {
                        this.value = "";
                    }
                });
            });
        },
        activateBlockOut() {
            // window.scrollTo(0, 0);
            document.getElementById("z1050-over").style.display = "block";
            document.documentElement.style.overflow = "hidden";
        },
        deactivateBlockOut() {
            document.getElementById("z1050-over").style.display = "none";
            document.documentElement.style.overflow = "auto";
        },
        parseDate(date) {
            let jsonString = JSON.stringify(date);
            let jsonParse = JSON.parse(jsonString);
            return new Date(jsonParse).toLocaleDateString("nl-NL", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
        apiGetRequest(url, options) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .get(url, options)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async updateOrderlineStatus(guid, updateJSON) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .post(`v1/order-line/${guid}/status`, updateJSON, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async syncOrderLineStatus(guid) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .get(`v1/order-line/${guid}/sync_status`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        //blob to dataURL
        blobToDataURL(blob, callback) {
            var a = new FileReader();
            a.onload = function(e) {
                callback(e.target.result);
            };
            a.readAsDataURL(blob);
        },
        async renderPreview(file, canvas) {
            return new Promise((resolve, reject) => {
                // const worker = new pdfjsLib.PDFWorker();
                pdfjsLib
                    .getDocument({
                        url: file.url,
                        // worker: worker,
                        disableAutoFetch: true,
                        disableStream: false,
                        verbosity: 0,
                    })
                    .then(async (pdf) => {
                        await pdf.getPage(1).then(async (page) => {
                            // gets the viewport of the page
                            // contains the width and the heightof the page in points

                            let viewport = page.getViewport({ scale: 1 });
                            // sets the height and with of the canvas to that of the viewport
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;

                            const context = canvas.getContext("2d");
                            // renders the page in the canvas
                            await page
                                .render({
                                    canvasContext: context,
                                    viewport: viewport,
                                })
                                .then(() => {
                                    // converts point to ...
                                    const width = Math.round(
                                        Math.round(page.view[2]) / 2.83465
                                    ).toFixed(1);
                                    const height = Math.round(
                                        Math.round(page.view[3]) / 2.83465
                                    ).toFixed(1);
                                    const size = `${width}x${height}mm`;
                                    resolve(size);
                                });
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        getCustomerByGuid(guid) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`v1/customer/${guid}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },

        async downloadFile(fileUrl, orderLineData) {
            await fetch(fileUrl).then((data) => {
                console.log(orderLineData);
                data.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");

                    a.style.display = "none";
                    a.href = url;
                    // a.download = fileUrl.split("/")[1];
                    a.download = orderLineData.seller_order_line_ref;

                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    window.URL.revokeObjectURL(url);
                });
            });
        },

        async uploadFile(orderLineGuid, version, PDFFile) {
            const bodyFormData = new FormData();
            bodyFormData.append("guid", this.generateGUID());
            bodyFormData.append("order_line_guid", orderLineGuid);
            bodyFormData.append("version", version);
            bodyFormData.append("pdf_file", PDFFile);
            return new Promise((resolve, reject) => {
                axios
                    .post(`v1/file`, bodyFormData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async getOrder(orderguid) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .get(`v1/order/${orderguid}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async getCustomer(customerguid) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .get(`v1/customer/${customerguid}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async getCustomerOrders(customerguid) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .get(`v1/customer/${customerguid}/order`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async getOrderLines(queryParams) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .get(`v1/order-line`, { params: queryParams })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async getOrderLine(guid) {
            return new Promise((resolve, reject) => {
                webApiAxios
                    .get(`v1/order-line/${guid}`)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async updateOrderline(guid, updateJSON) {
            webApiAxios.defaults.headers.put["Content-Type"] =
                "application/json";
            // console.log(updateJSON);
            return new Promise((resolve, reject) => {
                webApiAxios
                    .put(`v1/order-line/${guid}`, updateJSON, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            });
        },
        async checkOrderStatus(orderGuid) {
            return await servicesAxios
                .get("order/status-check", { params: { orderGuid } })
                .then((result) => {
                    // returns boolean indicating if the order is closed
                    return result.data.orderIsClosed;
                });
        },
        formatToSQLDate(date) {
            return dateFormat(date, "yyyy-mm-dd HH:MM:ss");
        },
        formatToShowDate(date) {
            return dateFormat(date, "dd-mm-yyyy HH:MM");
        },
        formatToProductionFileDate(date) {
            i18n.dayNames = [
                "Zon",
                "Maa",
                "Din",
                "Woe",
                "Don",
                "Vri",
                "Zat",
                "Zondag",
                "Maandag",
                "Dinsdag",
                "Woensdag",
                "Donderdag",
                "Vrijdag",
                "Zaterdag",
            ];

            return dateFormat(date, "dddd, dd-mm-yyyy");
        },
        formatToDayOfWeek(date) {
            return dateFormat(date, "ddd");
        },
        async createAddress(customerGuid, addressData) {
            const config = { headers: { "content-type": "application/json" } };
            return new Promise((resolve, reject) => {
                webApiAxios
                    .post(
                        `v1/customer/${customerGuid}/address`,
                        addressData,
                        config
                    )
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log("err", err.response);
                    });
            });
        },
        async createOrder(orderData) {
            const config = { headers: { "content-type": "application/json" } };
            return new Promise((resolve, reject) => {
                webApiAxios
                    .post(`v1/order/`, orderData, config)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log("err", err.response);
                    });
            });
        },
        async createCustomer(customerData) {
            const config = { headers: { "content-type": "application/json" } };
            return new Promise((resolve, reject) => {
                webApiAxios
                    .post(`v1/customer/`, customerData, config)
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log("err", err.response);
                    });
            });
        },
        async getUsers() {
            console.log(this.$auth);
            return await servicesAxios
                .get("oms/user")
                .then((result) => {
                    return result.data;
                })
                .catch((e) => console.log(e));
        },
        async getUserByGuid(user_guid) {
            return await servicesAxios
                .get("oms/user", { params: { user_guid } })
                .then((result) => {
                    return result.data;
                })
                .catch((e) => console.log(e));
        },
        async getUserByEmail(user_email) {
            return await servicesAxios
                .get("oms/user", { params: { user_email } })
                .then((result) => {
                    return result.data;
                })
                .catch((e) => console.log(e));
        },
        async getUserExists(user_email) {
            return await servicesAxios
                .get("oms/user/exists", {
                    params: { user_email },
                })
                .then((result) => {
                    return result.data;
                })
                .catch((e) => console.log(e));
        },
        async createUser(userData) {
            // console.log(localStorage.getItem("@@auth0spajs@@::WA92EgVmI07HI5fEAZXv8DQ4sriDgv0Q::organizer::openid profile email offline_access"));
            // servicesAxios.interceptors.request.use(request => {
            //    console.log('Starting Request', JSON.stringify(request, null, 2))
            //    return request
            //  })

            alert("Making request");
            return await servicesAxios.post("oms/user", userData);
        },
        async getOrderComments(orderGuid) {
            servicesAxios.interceptors.request.use((request) => {
                console.log(
                    "Starting Request",
                    JSON.stringify(request, null, 2)
                );
                return request;
            });

            return await servicesAxios
                .get("order-comment", { params: { orderGuid } })
                .then((result) => {
                    console.log(result);
                    return result;
                })
                .catch((e) => console.log(e));
        },
        async createOrderComment(postData) {
            return await servicesAxios
                .post("order-comment", postData)
                .then((result) => {
                    return result;
                })
                .catch((e) => console.log(e));
        },
        async getOrderlineComments(orderlineGuid) {
            return await servicesAxios
                .get("orderline-comment", { params: { orderlineGuid } })
                .then((result) => {
                    console.log(result);
                    return result;
                })
                .catch((e) => console.log(e));
        },
        async createOrderlineComment(postData) {
            return await servicesAxios
                .post("orderline-comment", postData)
                .then((result) => {
                    return result;
                })
                .catch((e) => console.log(e));
        },
        async getProductionLines() {
            return await webApiAxios
                .get("v1/production-line")
                .then((result) => {
                    return result.data;
                });
        },
        async generateProductionPDF(pdfData) {
            try {
                const colors = {
                    Maa: rgb(255 / 255, 38 / 255, 38 / 255),
                    Din: rgb(51 / 255, 169 / 255, 49 / 255),
                    Woe: rgb(255 / 255, 128 / 255, 0),
                    Don: rgb(76 / 255, 98 / 255, 247 / 255),
                    Vri: rgb(206 / 255, 0, 206 / 255),
                };

                const attributes = Object.assign(
                    {
                        Oplage: pdfData.run_volume,
                    },
                    pdfData.productJson
                );
                let isPickUp = pdfData.isPickUp;

                // Create a new PDFDocument
                const pdfDoc = await PDFDocument.create();

                // Embed the Times Roman font
                const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
                const boldFont = await pdfDoc.embedFont(
                    StandardFonts.HelveticaBold
                );

                // Add a blank page to the document
                const page = pdfDoc.addPage();

                // Get the width and height of the page
                const { height, width } = page.getSize();

                // Draw a string of text toward the top of the page
                let fontSize = 20;
                page.drawRectangle({
                    x: 0,
                    y: height - 125,
                    width: width,
                    height: 125,
                    color: colors[pdfData.shippingDay],
                });
                page.drawText(pdfData.shippingDay, {
                    x: 450,
                    y: height - 4 * fontSize,
                    size: 60,
                    font: font,
                    color: rgb(1, 1, 1),
                });
                page.drawText("Verkoper:", {
                    x: 20,
                    y: height - 2 * fontSize,
                    size: fontSize,
                    font: font,
                    color: rgb(1, 1, 1),
                });
                page.drawText(pdfData.sellerRef, {
                    x: 220,
                    y: height - 2 * fontSize,
                    size: fontSize,
                    font: font,
                    color: rgb(1, 1, 1),
                });
                page.drawText("Order Line REF:", {
                    x: 20,
                    y: height - 3 * fontSize,
                    size: fontSize,
                    font: font,
                    color: rgb(1, 1, 1),
                });
                page.drawText(pdfData.orderLineRef, {
                    x: 220,
                    y: height - 3 * fontSize,
                    size: fontSize,
                    font: font,
                    color: rgb(1, 1, 1),
                });
                page.drawText("Verzenddatum:", {
                    x: 20,
                    y: height - 5 * fontSize,
                    size: 12,
                    font: font,
                    color: rgb(1, 1, 1),
                });
                page.drawText(pdfData.shippingDate, {
                    x: 220,
                    y: height - 5 * fontSize,
                    size: 12,
                    font: font,
                    color: rgb(1, 1, 1),
                });
                page.drawText("Order referentie: " + pdfData.orderRef, {
                    x: 20,
                    y: height - 8 * fontSize,
                    size: 16,
                    font: font,
                    color: rgb(0, 0, 0),
                });

                let offsetHeight = 10;
                let totalValues = 0;
                for (let item of Object.keys(attributes)) {
                    let values = [];
                    if (totalValues > 20) {
                        break;
                    }

                    // TODO: IF errors on other linebreak indicators: Replace other synonyms of \n with \n to split only on \n

                    const lineBreakChunks = attributes[item]
                        .toString()
                        .split("\n");

                    for (let i = 0; i < lineBreakChunks.length; i++) {
                        if (totalValues >= 20) {
                            values.push("Zie OMS voor meer info");
                            break;
                        }
                        const lineBreakChunk = lineBreakChunks[i];
                        let chunks = Math.ceil(lineBreakChunk.length / 60);
                        for (let i = 1; i <= chunks; i++) {
                            values.push(
                                lineBreakChunk.substring(60 * i - 60, 60 * i)
                            );
                            totalValues++;
                            if (totalValues > 20) {
                                break;
                            }
                        }
                    }

                    page.drawText(item + ":", {
                        x: 20,
                        y: height - offsetHeight * fontSize,
                        size: 12,
                        font: font,
                        color: rgb(0, 0, 0),
                    });
                    values.forEach((value) => {
                        page.drawText(value, {
                            x: 220,
                            y: height - offsetHeight * fontSize,
                            size: 12,
                            font: font,
                            color: rgb(0, 0, 0),
                        });
                        offsetHeight++;
                    });
                }

                if (isPickUp) {
                    offsetHeight += 2;
                    page.drawText("LET OP, AFHAAL ORDER", {
                        x: 20,
                        y: height - offsetHeight * fontSize,
                        size: 20,
                        font: font,
                        color: rgb(1, 0, 0),
                    });
                    offsetHeight++;
                }

                page.drawText("Opmerkingen", {
                    x: 20,
                    y: height - offsetHeight * fontSize,
                    size: 15,
                    font: font,
                    color: rgb(0, 0, 0),
                });
                offsetHeight++;

                for (let comment of pdfData.orderlineComments) {
                    page.drawText(comment.userEmail, {
                        x: 20,
                        y: height - offsetHeight * fontSize,
                        size: 12,
                        font: font,
                        color: rgb(0, 0, 0),
                    });
                    page.drawText(comment.body, {
                        x: 220,
                        y: height - offsetHeight * fontSize,
                        size: 12,
                        font: font,
                        color: rgb(0, 0, 0),
                    });
                    offsetHeight++;
                }

                // const sourcePdfUrl = 'https://pdf-lib.js.org/assets/with_large_page_count.pdf'
                let sourcePdf;

                try {
                    const sourcePdfUrl = pdfData.fileUrl;
                    sourcePdf = await fetch(sourcePdfUrl).then((res) =>
                        res.arrayBuffer()
                    );
                } catch (error) {
                    console.error(error);
                    throw error;
                }

                try {
                    console.log("Loading pdfPReviewDoc");
                    const pdfPreviewDoc = await PDFDocument.load(sourcePdf);

                    console.log(pdfPreviewDoc);

                    const embeddedPages = await pdfDoc.embedPdf(sourcePdf, [0]);

                    const embeddedPage = embeddedPages[0];

                    const dimensionRatio =
                        embeddedPage.height / embeddedPage.width;

                    page.drawPage(embeddedPage, {
                        x: 20,
                        y: 10,
                        width: 180,
                        height: 180 * dimensionRatio,
                    });
                } catch (error) {
                    page.drawText("PDF kon niet worden ingeladen", {
                        x: 20,
                        y: 10,
                        size: 20,
                        font: font,
                        color: rgb(1, 0, 0),
                    });

                    console.error("Error embedding pdf page", error);
                }

                let printInfoY = 120;
                let printInfoStartX = 440;

                page.drawText("Geprint: ", {
                    x: printInfoStartX,
                    y: printInfoY,
                    size: 15,
                    font: boldFont,
                    color: rgb(0, 0, 0),
                });

                let textWidth = boldFont.widthOfTextAtSize("Geprint: ", 15);

                page.drawText(pdfData.printed_qty || "0", {
                    x: printInfoStartX + Math.max(textWidth, 80),
                    y: printInfoY,
                    size: 15,
                    font: font,
                    color: rgb(0, 0, 0),
                });

                printInfoY -= 20;

                page.drawText("Inschiet: ", {
                    x: printInfoStartX,
                    y: printInfoY,
                    size: 15,
                    font: boldFont,
                    color: rgb(0, 0, 0),
                });

                textWidth = boldFont.widthOfTextAtSize("Inschiet: ", 15);

                page.drawText(pdfData.extra_printed_qty || "0", {
                    x: printInfoStartX + Math.max(textWidth, 80),
                    y: printInfoY,
                    size: 15,
                    font: font,
                    color: rgb(0, 0, 0),
                });

                printInfoY -= 20;

                page.drawText("Geleverd: ", {
                    x: printInfoStartX,
                    y: printInfoY,
                    size: 15,
                    font: boldFont,
                    color: rgb(0, 0, 0),
                });

                textWidth = boldFont.widthOfTextAtSize("Geleverd: ", 15);

                page.drawText(pdfData.delivered_qty || "0", {
                    x: printInfoStartX + Math.max(textWidth, 80),
                    y: printInfoY,
                    size: 15,
                    font: font,
                    color: rgb(0, 0, 0),
                });

                // Serialize the PDFDocument to bytes (a Uint8Array)
                const pdfBytes = await pdfDoc.save();
                var blob = new Blob([pdfBytes], {
                    type: "application/pdf",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                var fileName = "production-data-" + pdfData.orderLineRef;
                link.download = fileName;
                link.click();
                return;
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        async sendWithCarrier(shipmentData, carrier) {
            return await servicesAxios
                .post("shipment", shipmentData, {
                    params: { carrier },
                    responseType: "arraybuffer",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/pdf",
                        "Access-Control-Expose-Headers": "Tracker-Code",
                    },
                })
                .then((result) => {
                    const body = result.data;
                    let trackerCode = result.headers["tracker-code"]
                        ? result.headers["tracker-code"]
                        : result.headers["Tracker-Code"];
                    if (!trackerCode) {
                        trackerCode = "no trackercode header";
                    }
                    return { body, trackerCode };
                })
                .catch((error) => {
                    console.log(error);
                    //   return { error };
                });
        },
        async getSendlabelWithTrackerCode(trackerCode, carrier) {
            return await servicesAxios
                .get("shipment/label", {
                    params: { trackerCode, carrier },
                    responseType: "arraybuffer",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/pdf",
                        "Access-Control-Expose-Headers": "Tracker-Code",
                    },
                })
                .then((result) => {
                    // console.log(result);
                    const body = result.data;
                    return { body };
                });
        },
        // async getDHLAccessToken() {
        //    return await fetch('https://api-gw.dhlparcel.nl/authenticate/api-key', {
        //       method: 'POST',
        //       headers: {
        //          'Accept': 'application/json',
        //          'Content-Type': 'application/json'
        //       },
        //       body: JSON.stringify({ userId: "cd712cf4-7a86-42c2-bbf7-7d3ec3a64841", key: "3c12500f-c6e1-402a-b668-ebc65110db3d" })
        //    })
        //       .then(response => {
        //          if (response.status !== 200) throw new Error("Authentication failed, please contact you webmaster.");
        //          return response.json();
        //       })
        // },
        async validateAddress(addressData) {
            return await servicesAxios
                .post("address/validate", addressData)
                .then((result) => {
                    return result.data;
                });
        },
        async acceptFileAxiosCall(data, orderlineGuid) {
            return await webApiAxios.post(`v1/file/${orderlineGuid}/accept`, {
                comment: data.value,
            });
        },
        async declineFileAxiosCall(data, orderlineGuid) {
            return await webApiAxios.post(`v1/file/${orderlineGuid}/decline`, {
                comment: data.value,
            });
        },
        errorModal(error) {
            console.log(error);
            if (typeof error === "string") {
                return this.$swal({
                    icon: "error",
                    title: "Error",
                    text: error,
                    confirmButtonText: "OK",
                });
            }

            this.$swal({
                icon: "error",
                title: "Error",
                text: error.message,
                confirmButtonText: "OK",
            });
        },
    },
};
