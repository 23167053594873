<template>
  <div class="search-bar-container">
    <!-- Check that the SDK client is not currently loading before accessing is methods -->
    <div class="row w-100 py-3" v-if="!$auth.loading.value">
      <div class="col-12 justify-content-center">
        <input
          type="text"
          name="search-bar"
          id="search-bar"
          class="search-bar"
          :placeholder="this.placeHolder"
        />
        <button
          name="add-search-button"
          id="add-search-button"
          class="add-search-button"
          v-on:click="resetQuery"
        >
          <i class="fas fa-undo"></i>
        </button>
        <button
          type="submit"
          name="search-button"
          id="search-button"
          class="search-button"
          v-on:click="getQueriedOrders"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    if (this.type == "order") {
      this.placeHolder = "Zoek een order...";
    }
    if (this.type == "customer") {
      this.placeHolder = "Zoek een customer...";
    }
    return {};
  },
  mounted() {
    document.querySelector("#search-bar").addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        this.getQueriedOrders();
      }
    });
    document.querySelector("#search-bar").addEventListener("input", (e) => {
      if(!e.target.value){
          this.resetQuery();
      }
    });
  },
  name: "SearchBar",
  props: ["type"],
  methods: {
    resetQuery() {
      document.querySelector("#search-bar").value = "";
      this.$emit("resetQuery");
    },
    async getQueriedOrders() {
      const searchQuery = document.querySelector("#search-bar").value;
      this.$emit("ordersResultUpdate", searchQuery);
    },
  },
};
</script>

<style>
#queries {
  height: 2.5em;
  border-radius: 24px;
  border-color: #dfe1e5;
  border-width: 1px;
  border-style: solid;
  padding-left: 1em;
}
.search-bar-container {
  display: flex;
}
.search-bar {
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-color: #dfe1e5;
  border-width: 1px;
  border-style: solid;
  width: 90%;
  height: 2.5em;
  padding: 1em;
  vertical-align: top;
}
.search-bar:focus {
  outline: none;
}
.search-bar:focus::placeholder {
  color: whitesmoke;
}
.search-bar:focus::-moz-placeholder {
  color: whitesmoke;
}
.search-bar:focus::-webkit-input-placeholder {
  color: whitesmoke;
}
.search-button {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  border-color: #dfe1e5;
  border-width: 1px;
  border-style: solid;
  width: 5%;
  height: 2.5em;
  background: white;
  color: #9aa0a6;
}
.add-search-button {
  border-color: #dfe1e5;
  border-width: 1px;
  border-style: solid;
  width: 5%;
  height: 2.5em;
  background: white;
  color: #9aa0a6;
}
</style>