<template>
  <div class="table-responsive-xl">
    <table class="table table-borderless text-start" aria-describedby="CustomersList">
      <thead>
        <tr class="text-uppercase">
          <th scope="col">Id</th>
          <th scope="col">Email</th>
          <th scope="col">Naam</th>
          <th scope="col">Telefoonnr</th>
          <th scope="col">Referentie</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-active" v-for="customer in customers" :key="customer.id">
          
          <th class="primary-th" scope="row">{{ customer.id }}</th>
          <td>{{ customer.email }}</td>
          <td>{{ customer.name }}</td>
          <td>{{ customer.phonenumber }}</td>
          <td>{{ customer.referral }}</td>
          <td class="table-btn">
            <router-link
              :to="{
                name: 'CustomerDetail',
                params: {
                  guid: customer.guid,
                  referral: customer.referral,
                },
              }"
            >
              <i class="fas fa-chevron-right"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
 
<script>
// import axios
import axios from "axios";

export default {
  name: "CustomerList",
  data() {
    return {
      customers: [],
    };
  },

  created() {
    this.getCustomers();
  },

  methods: {
    // Get All customers
    async getCustomers() {
      await axios
        .get("v1/customer")
        .then((response) => {
          this.customers = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
 
<style>
.fa-chevron-right {
  padding: 5px 15px;
  color: #131315;
}
</style>