<template>
    <div :style="dynamicStyles" class="cus-modal" v-if="showModal">
        <div class="cus-modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
        };
    },
    props: {
        top: {
            type: String,
            default: "10%",
        },
        left: {
            type: String,
            default: "10%",
        },
        width: {
            type: String,
            default: "80%",
        },
        height: {
            type: String,
            default: "80%",
        },
    },
    computed: {
        dynamicStyles() {
            return {
                top: this.top,
                left: this.left,
                width: this.width,
                height: this.height,
            };
        },
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
    },
};
</script>
<style scoped>
/* Add your CSS styles for the modal here */
.cus-modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    box-shadow: black 0px 0px 10px;
}

.cus-modal-content {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    align-items: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}
</style>
