<template>
  <div class="page-header py-4 ps-5">
    <h1 class="text-start pt-3 pb-1 m-0">{{ pageName }}</h1>
  </div>
</template>

<script>
export default {
    name: 'PageHeader',
    props:  {
        pageName: String
    }
};
</script>

<style>
.page-header {
  background-color: #f6f6f6;
}

.page-header h1 {
  font-size: 1.3rem;
  font-weight: bold;
}
</style>