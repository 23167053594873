import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import axios from 'axios';
import { webApiAxios, servicesAxios } from "@/config/index.js";

// Auth0
import { setupAuth } from '@/auth';

const authConfig = {};

authConfig.client_id = process.env.VUE_APP_CLIENT_ID;
authConfig.domain = process.env.VUE_APP_AUTH0_DOMAIN;
authConfig.audience = process.env.VUE_APP_AUTH0_AUDIENCE;
authConfig.serverUrl = process.env.VUE_APP_AUTH0_AUDIENCE;
authConfig.redirect_uri = process.env.VUE_APP_REDIRECT_URI;

// SidebarMenu
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';

// Mixins
import mixins from '@/mixin';

// Alerts
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// Set base url
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

let app = createApp(App);

app.use(router);

app.use(VueSidebarMenu);
app.use(VueSweetalert2);

function callbackRedirect(appState) {
   router.push(appState && appState.targetUrl ? appState.targetUrl : '/');
}

setupAuth(authConfig, callbackRedirect).then((auth) => {
   app.mixin(mixins);
   const token = JSON.parse(localStorage.getItem(`@@auth0spajs@@::${process.env.VUE_APP_CLIENT_ID}::organizer::openid profile email offline_access`)).body.access_token;
   axios.defaults.headers.common['Authorization'] = "Bearer " + token;
   webApiAxios.interceptors.request.use(function (config) {
      config.headers.Authorization = "Bearer " + token;
      return config;
   }, function (error) {
      alert("error");
      return error;
   });
   servicesAxios.interceptors.request.use(function (config) {
      config.headers.Authorization = "Bearer " + token;
      return config;
   }, function (error) {
      alert("error fvndaof");
      return error;
   });
   app.use(auth).mount('#app');
});
