<template>
    <div class="customerDetail">
        <page-header pageName="Klantpagina" />
        <div class="container px-5 py-4">
            <div class="row mb-5 text-start">
                <div class="col">
                    <h2>
                        {{ this.customerData.name }}
                        <span v-if="this.customerData.company">
                            - {{ this.customerData.company }}
                        </span>
                    </h2>
                    <p>{{ this.customerData.email }}</p>
                    <p>{{ this.customerData.phonenumber }}</p>
                    <p>{{ this.customerData.referral }}</p>
                    <p v-if="this.customerData.debtor">
                        {{ this.customerData.debtor }}
                    </p>
                </div>
                <div class="col">
                    <h2>Statistieken: (oid)</h2>
                    <p>Aantal orders:</p>
                    <p>Omzet:</p>
                    <p>Klachten:</p>
                    <p>Opmerkingen bij geplaatst:</p>
                </div>
            </div>
            <div class="row pt-5 mb-5 text-start border-top">
                <h2>Adressen</h2>
                <div class="row">
                    <div class="col">
                        <h3>
                            Standaard Factuuradres <i class="fas fa-edit"></i>
                        </h3>
                        <div
                            v-for="address in this.customerData.addresses"
                            :key="address.guid"
                        >
                            <div v-if="address.type == 'Main Billing'">
                                <p>Bedrijf: {{ address.company }}</p>
                                <p>Naam: {{ address.name }}</p>
                                <p>Straat: {{ address.address }}</p>
                                <p>Postcode: {{ address.postal_code }}</p>
                                <p>Stad: {{ address.city }}</p>
                                <p>Land: {{ address.country }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <h3>
                            Standaard Verzendadres <i class="fas fa-edit"></i>
                        </h3>
                        <div
                            v-for="address in this.customerData.addresses"
                            :key="address.guid"
                        >
                            <div v-if="address.type == 'Main Shipping'">
                                <p>Bedrijf: {{ address.company }}</p>
                                <p>Naam: {{ address.name }}</p>
                                <p>Straat: {{ address.address }}</p>
                                <p>Postcode: {{ address.postal_code }}</p>
                                <p>Stad: {{ address.city }}</p>
                                <p>Land: {{ address.country }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <div class="row pt-5 mb-5 text-start border-top">
                <h2 class="mb-4">Acties</h2>
                <div class="row d-flex">
                    <div class="col">
                        <button
                            @click="showNewOrderModal"
                            class="btn btn-primary me-3"
                        >
                            Nieuwe order
                        </button>
                        <button
                            class="btn btn-primary mx-3"
                            @click="openInvoiceOrderModal"
                        >
                            Nieuwe order vanuit factuur
                        </button>
                        <modal-component ref="invoiceOrderModal">
                            <h1>Importeer Factuur Naar Order</h1>
                            <form
                                v-if="!invoiceToOrderForm.orderLine.price"
                                class="import-invoice-form"
                                @submit.prevent="importInvoice($event)"
                            >
                                <label for="administration">
                                    Administratie
                                </label>
                                <select
                                    id="administration"
                                    v-model="importInvoiceForm.administration"
                                >
                                    <option value="Almanakken" active
                                        >Almanakken</option
                                    >
                                    <option value="OCC Crossmedia B.V." active
                                        >OCC Crossmedia BV</option
                                    >
                                </select>
                                <label for="invoiceId" class="mt-3"
                                    >Factuur ID
                                    <br />
                                    <small>Deze vind je in de url</small></label
                                >
                                <input
                                    type="text"
                                    id="invoiceId"
                                    v-model="importInvoiceForm.invoiceId"
                                />
                                <button
                                    type="submit"
                                    class="btn btn-primary mt-3"
                                >
                                    Importeer
                                </button>
                            </form>
                            <form
                                id="invoice-to-order-form"
                                v-if="invoiceToOrderForm.orderLine.price"
                                @submit.prevent="submitInvoiceToOrder($event)"
                            >
                                <label for="inv-seller_ref" class="form-label"
                                    >Order referentie/beschrijving</label
                                >
                                <input
                                    required
                                    maxlength="100"
                                    placeholder="test000001"
                                    v-model="invoiceToOrderForm.sellerRef"
                                    type="text"
                                    class="form-control"
                                    id="inv-seller_ref"
                                    name="seller_ref"
                                    aria-describedby="order-ref"
                                />
                                <label for="inv-order-ref" class="form-label"
                                    >Referentie</label
                                >
                                <input
                                    v-model="invoiceToOrderForm.orderRef"
                                    required
                                    maxlength="255"
                                    placeholder="mooie flyer"
                                    type="text"
                                    class="form-control"
                                    id="ìnv-order-comment"
                                    name="comment"
                                    aria-describedby="order-ref"
                                />
                                <label for="order-comment" class="form-label"
                                    >Opmerking</label
                                >
                                <input
                                    v-model="invoiceToOrderForm.comment"
                                    maxlength="255"
                                    placeholder="mooie flyer"
                                    type="text"
                                    class="form-control"
                                    id="order-comment"
                                    name="order-comment"
                                    aria-describedby="order-ref"
                                />
                                <h2>Adres</h2>
                                <div class="mb-3">
                                    <label for="address-name" class="form-label"
                                        >Naam (voor- en achternaam)</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.address.name
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="address-name"
                                        name="name"
                                        aria-describedby="address-name"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="address-company"
                                        class="form-label"
                                        >Bedrijf</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.address.company
                                        "
                                        type="text"
                                        class="form-control"
                                        id="address-company"
                                        name="company"
                                        aria-describedby="address-company"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="address-address"
                                        class="form-label"
                                        >Address: (straat + huisnummer)</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.address.address
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="address-address"
                                        name="address"
                                        aria-describedby="address-address"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="address-postal_code"
                                        class="form-label"
                                        >Postcode:</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.address
                                                .postal_code
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="address-postal_code"
                                        name="postal_code"
                                        aria-describedby="address-postal_code"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label for="address-city" class="form-label"
                                        >Stad:</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.address.city
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="address-city"
                                        name="city"
                                        aria-describedby="address-city"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        class="form-label"
                                        for="select-country"
                                        >Land:
                                    </label>
                                    <select
                                        v-model="
                                            invoiceToOrderForm.address.country
                                        "
                                        id="address-country"
                                        name="country"
                                        class="form-select"
                                        aria-label="Default select example"
                                    >
                                        <option>Open this select menu</option>
                                        <option selected value="NL"
                                            >Nederland</option
                                        >
                                        <option value="BE">België</option>
                                    </select>
                                </div>
                                <h2>Orderregel</h2>
                                <div>
                                    <label
                                        for="orderline-ref"
                                        class="form-label"
                                        >Orderregel referentie:</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine
                                                .seller_order_line_ref
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="orderline-ref"
                                        name="seller_order_line_ref"
                                        aria-describedby="orderline-ref"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="orderline-lead_time"
                                        class="form-label"
                                        >Productiedagen</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine
                                                .lead_time
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="orderline-lead_time"
                                        name="lead_time"
                                        aria-describedby="orderline-lead_time"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="orderline-price"
                                        class="form-label"
                                        >Prijs</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine.price
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="orderline-price"
                                        name="price"
                                        aria-describedby="orderline-price"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="orderline-production_method"
                                        class="form-label"
                                        >Productie methode</label
                                    >
                                    <select
                                        v-model="
                                            invoiceToOrderForm.orderLine
                                                .production_method
                                        "
                                        class="form-select"
                                        name="production_method"
                                        id="orderline-production_method"
                                    >
                                        <option selected value="Digitaal"
                                            >Digitaal</option
                                        >
                                        <option value="Offset">Offset</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="orderline-run_volume"
                                        class="form-label"
                                        >Oplage</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine
                                                .run_volume
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="orderline-run_volume"
                                        name="run_volume"
                                        aria-describedby="orderline-run_volume"
                                    />
                                </div>
                                <div class="mb-3">
                                    <label
                                        class="form-label"
                                        for="select-express_delivery"
                                        >Expresser:
                                    </label>
                                    <select
                                        v-model="
                                            invoiceToOrderForm.orderLine
                                                .express_delivery
                                        "
                                        id="address-express_delivery"
                                        name="express_delivery"
                                        class="form-select"
                                        aria-label="Default select example"
                                    >
                                        <option selected value="0">Nee</option>
                                        <option value="1">Ja</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label
                                        for="orderline-product_name"
                                        class="form-label"
                                        >Product naam:</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine.product
                                                .name
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="orderline-product_name"
                                        name="product-name"
                                        aria-describedby="orderline-product_name"
                                    />
                                </div>
                                <div class="col">
                                    <label
                                        for="product-dimension-width"
                                        class="form-label"
                                        >Breedte (mm)</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine.product
                                                .dimensions.width
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="product-dimension-width"
                                        name="product-dimension-width"
                                        aria-describedby="product-dimension-width"
                                    />
                                </div>
                                <div class="col">
                                    <label
                                        for="product-dimension-length"
                                        class="form-label"
                                        >Hoogte (mm)</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine.product
                                                .dimensions.length
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="product-dimension-length"
                                        name="product-dimension-length"
                                        aria-describedby="product-dimension-length"
                                    />
                                </div>
                                <div class="col">
                                    <label
                                        for="product-dimension-bleed"
                                        class="form-label"
                                        >Bleed (mm)</label
                                    >
                                    <input
                                        v-model="
                                            invoiceToOrderForm.orderLine.product
                                                .dimensions.bleed
                                        "
                                        required
                                        type="text"
                                        class="form-control"
                                        id="product-dimension-bleed"
                                        name="product-dimension-bleed"
                                        aria-describedby="product-dimension-bleed"
                                    />
                                </div>
                                <div
                                    class="col"
                                    v-for="(value, key) in invoiceToOrderForm
                                        .orderLine.product.product_json"
                                    :key="key"
                                >
                                    <label :for="'product-attribute-' + key">{{
                                        key
                                    }}</label>
                                    <textarea
                                        v-model="
                                            invoiceToOrderForm.orderLine.product
                                                .product_json[key]
                                        "
                                        :id="'product-attribute-' + key"
                                        :name="'product-attribute-' + key"
                                        class="form-control"
                                        type="text"
                                    ></textarea>
                                </div>
                                <div class="col">
                                    <button
                                        class="btn btn-primary"
                                        type="submit"
                                    >
                                        Maak order
                                    </button>
                                </div>
                            </form>
                        </modal-component>
                        <button
                            class="btn btn-primary mx-3"
                            style="background-color: grey!important;"
                        >
                            Mail versturen
                        </button>
                        <button
                            class="btn btn-primary mx-3"
                            style="background-color: grey!important;"
                        >
                            Facturen ophalen
                        </button>
                    </div>
                </div>
            </div>
            <div class="row pt-5 text-start border-top">
                <h2>Laatste orders:</h2>
                <order-list id="order-list" :orders="this.orders" />
            </div>
        </div>
        <div class="customer-modal" id="new-order-modal">
            <button
                @click="closeNewOrderModal"
                class="btn btn-primary float-end"
            >
                x
            </button>
            <div class="border-bottom px-3 my-4"><h3>Nieuwe order</h3></div>
            <div class="p-3 text-start">
                <form
                    @submit.prevent="submitOrder($event)"
                    @change="orderFormChanged"
                    id="order-form"
                >
                    <div class="mb-3">
                        <label for="order-ref" class="form-label"
                            >Order referentie/beschrijving</label
                        >
                        <input
                            required
                            maxlength="100"
                            placeholder="test000001"
                            type="text"
                            class="form-control"
                            id="order-ref"
                            name="seller_ref"
                            aria-describedby="order-ref"
                        />
                        <div id="order-ref-help" class="form-text">
                            Probeer een unieke referentie/omscrhijving te geven.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="order-ref" class="form-label"
                            >Opmerking</label
                        >
                        <input
                            required
                            maxlength="255"
                            placeholder="mooie flyer"
                            type="text"
                            class="form-control"
                            id="order-comment"
                            name="comment"
                            aria-describedby="order-ref"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="select-biling-address"
                            >Kies een factuuradres:
                        </label>
                        <button
                            type="button"
                            @click="openNewAddressModal"
                            class="btn btn-primary mb-1 float-end"
                        >
                            New
                        </button>
                        <select
                            required
                            id="order-address"
                            class="form-select"
                            name="address_guid"
                            aria-label="Default select example"
                        >
                            <option
                                v-for="address of customerData.addresses"
                                :key="address.guid"
                                :value="address.guid"
                            >
                                {{ address.address }},
                                {{ address.postal_code }}, {{ address.city }},
                                {{ address.country }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="shop_referral" class="form-label"
                            >Shopreferentie</label
                        >
                        <select
                            class="form-select"
                            name="shop_referral"
                            id="shop_referral"
                            required
                        >
                            <option value="">Maak een keuze</option>
                            <option value="Superdrukker">Superdrukker</option>
                            <option value="Printhub">Printhub</option>
                            <option value="OCC">OCC</option>
                            <option value="Artega">Artega</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="shipping_costs" class="form-label"
                            >Verzendkosten</label
                        >
                        <input
                            required
                            maxlength="4"
                            placeholder="0"
                            type="text"
                            class="form-control"
                            id="form-shipping_costs"
                            name="shipping_costs"
                            aria-describedby="form-shipping_costs"
                        />
                        <div id="order-ref-help" class="form-text">
                            Geef het totaal aan verzendkosten in als dit niet
                            inbegrepen is bij de order(regel) prijs.
                        </div>
                    </div>
                    <div class="mb-3 pt-2 border-top">
                        <h3>
                            Orderregels
                            <button
                                @click.prevent="showOrderlineModal"
                                type="submit"
                                class="btn btn-primary float-end"
                            >
                                Voeg orderregel toe
                            </button>
                        </h3>
                        <div v-if="this.orderlines">
                            <p
                                v-for="orderline in this.orderlines"
                                :key="orderline.guid"
                            >
                                {{ orderline.seller_order_line_ref }}
                            </p>
                        </div>
                    </div>
                    <button class="btn btn-primary mt-5" type="submit">
                        Submit
                    </button>
                </form>
            </div>
        </div>
        <div class="customer-modal" id="new-address-modal">
            <button
                @click="closeNewAddressModal"
                class="btn btn-primary float-end"
            >
                X
            </button>
            <div class="border-bottom px-3 my-4"><h3>Nieuw address</h3></div>
            <div class="p-3 text-start">
                <form @submit.prevent="newAddress($event)">
                    <div class="mb-3">
                        <label for="address-name" class="form-label"
                            >Naam (voor- en achternaam)</label
                        >
                        <input
                            value="Casper Mol"
                            required
                            type="text"
                            class="form-control"
                            id="address-name"
                            name="name"
                            aria-describedby="address-name"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="address-company" class="form-label"
                            >Bedrijf</label
                        >
                        <input
                            value="OCC Crossmedia BV"
                            type="text"
                            class="form-control"
                            id="address-company"
                            name="company"
                            aria-describedby="address-company"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="address-address" class="form-label"
                            >Address: (straat + huisnummer)</label
                        >
                        <input
                            value="Prinsenkade 9a"
                            required
                            type="text"
                            class="form-control"
                            id="address-address"
                            name="address"
                            aria-describedby="address-address"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="address-postal_code" class="form-label"
                            >Postcode:</label
                        >
                        <input
                            value="4811 VB"
                            required
                            type="text"
                            class="form-control"
                            id="address-postal_code"
                            name="postal_code"
                            aria-describedby="address-postal_code"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="address-city" class="form-label"
                            >Stad:</label
                        >
                        <input
                            value="Breda"
                            required
                            type="text"
                            class="form-control"
                            id="address-city"
                            name="city"
                            aria-describedby="address-city"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="select-country"
                            >Land:
                        </label>
                        <select
                            id="address-country"
                            name="country"
                            class="form-select"
                            aria-label="Default select example"
                        >
                            <option>Open this select menu</option>
                            <option selected value="NL">Nederland</option>
                            <option value="BE">België</option>
                        </select>
                    </div>
                    <div>
                        <button class="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
        <div
            v-if="this.orderFormData"
            class="orderline-modal"
            id="new-orderline-modal"
        >
            <div class="border-bottom px-3 my-4">
                <h3>Nieuwe orderregel</h3>
            </div>
            <div class="p-3 text-start">
                <form @submit.prevent="newOrderline($event)">
                    <div class="mb-3">
                        <label for="orderline-ref" class="form-label"
                            >Orderregel referentie:</label
                        >
                        <input
                            :value="
                                this.orderFormData.seller_ref +
                                    '-' +
                                    this.orderlineIncrement
                            "
                            required
                            type="text"
                            class="form-control"
                            id="orderline-ref"
                            name="seller_order_line_ref"
                            aria-describedby="orderline-ref"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="orderline-lead_time" class="form-label"
                            >Productiedagen</label
                        >
                        <input
                            value="3"
                            required
                            type="text"
                            class="form-control"
                            id="orderline-lead_time"
                            name="lead_time"
                            aria-describedby="orderline-lead_time"
                        />
                    </div>
                    <div class="mb-3">
                        <label for="orderline-price" class="form-label"
                            >Prijs</label
                        >
                        <input
                            value="99"
                            required
                            type="text"
                            class="form-control"
                            id="orderline-price"
                            name="price"
                            aria-describedby="orderline-price"
                        />
                    </div>
                    <div class="mb-3">
                        <label
                            for="orderline-production_method"
                            class="form-label"
                            >Productie methode</label
                        >
                        <select
                            class="form-select"
                            name="production_method"
                            id="orderline-production_method"
                        >
                            <option selected value="Digitaal">Digitaal</option>
                            <option value="Offset">Offset</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="orderline-run_volume" class="form-label"
                            >Oplage</label
                        >
                        <input
                            value="500"
                            required
                            type="text"
                            class="form-control"
                            id="orderline-run_volume"
                            name="run_volume"
                            aria-describedby="orderline-run_volume"
                        />
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="select-express_delivery"
                            >Expresser:
                        </label>
                        <select
                            id="address-express_delivery"
                            name="express_delivery"
                            class="form-select"
                            aria-label="Default select example"
                        >
                            <option selected value="0">Nee</option>
                            <option value="1">Ja</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="select-biling-address"
                            >Kies een verzendadres:
                        </label>
                        <button
                            type="button"
                            @click="openNewAddressModal(1150)"
                            class="btn btn-primary mb-1 float-end"
                        >
                            New
                        </button>
                        <select
                            required
                            id="order-address"
                            class="form-select"
                            name="address_guid"
                            aria-label="Default select example"
                        >
                            <option
                                selected
                                :value="this.orderFormData.address_guid"
                            >
                                Zelfde als factuuradres
                            </option>
                            <option
                                v-for="address of customerData.addresses"
                                :key="address.guid"
                                :value="address.guid"
                            >
                                {{ address.address }},
                                {{ address.postal_code }}, {{ address.city }},
                                {{ address.country }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="orderline-product_name" class="form-label"
                            >Product naam:</label
                        >
                        <input
                            value="Flyer"
                            placeholder="Flyer"
                            required
                            type="text"
                            class="form-control"
                            id="orderline-product_name"
                            name="product-name"
                            aria-describedby="orderline-product_name"
                        />
                    </div>
                    <div class="mb-3 row">
                        <div class="col">
                            <label
                                for="product-dimension-width"
                                class="form-label"
                                >Breedte (mm)</label
                            >
                            <input
                                value="210"
                                placeholder="210"
                                required
                                type="text"
                                class="form-control"
                                id="product-dimension-width"
                                name="product-dimension-width"
                                aria-describedby="product-dimension-width"
                            />
                        </div>
                        <div class="col">
                            <label
                                for="product-dimension-length"
                                class="form-label"
                                >Hoogte (mm)</label
                            >
                            <input
                                value="297"
                                placeholder="297"
                                required
                                type="text"
                                class="form-control"
                                id="product-dimension-length"
                                name="product-dimension-length"
                                aria-describedby="product-dimension-length"
                            />
                        </div>
                        <div class="col">
                            <label
                                for="product-dimension-bleed"
                                class="form-label"
                                >Bleed (mm)</label
                            >
                            <input
                                value="3"
                                placeholder="3"
                                required
                                type="text"
                                class="form-control"
                                id="product-dimension-bleed"
                                name="product-dimension-bleed"
                                aria-describedby="product-dimension-bleed"
                            />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-2">
                            <p>Attributen:</p>
                        </div>
                        <div class="col-6">
                            <select
                                @change="loadProductPreset($event)"
                                class="form-select"
                                id="product-presets"
                            >
                                <option selected
                                    >Kies eventueel een preset</option
                                >
                                <option value="default">Default</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <p>
                            Laat de attribuut leeg als je een fuck up hebt
                            gemaakt en deze wil overslaan
                        </p>
                        <div
                            class="mb-3"
                            v-for="attribute of orderlineAttributes"
                            :key="attribute"
                        >
                            <label :for="'product-attribute-' + attribute">{{
                                attribute
                            }}</label>
                            <input
                                :id="'product-attribute-' + attribute"
                                :name="'product-attribute-' + attribute"
                                class="form-control"
                                type="text"
                            />
                        </div>
                        <div>
                            <select class="form-select" id="add-attribute">
                                <option selected>Voeg attribuut toe</option>
                                <option value="Bedrukking">Bedrukking</option>
                                <option value="Materiaal">Materiaal</option>
                                <option value="Omvang">Omvang</option>
                                <option value="Laminaat">Laminaat</option>
                                <option value="Afwerking">Afwerking</option>
                                <option value="Opmerking">Opmerking</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary">Submit</button>
                        <button type="reset">Reset</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// import axios
import PageHeader from "@/components/PageHeader.vue";
import OrderList from "@/components/order/OrderList.vue";
import ModalComponent from "@/components/Modal.vue";

export default {
    name: "customerDetail",
    components: {
        PageHeader,
        OrderList,
        ModalComponent,
    },
    data() {
        return {
            customerGuid: this.$route.params.guid,
            customerData: {},
            referral: this.$route.params.referral,
            orders: [],
            orderFormData: {},
            orderlines: [],
            orderlineIncrement: 0,
            orderlineAttributes: [],
            importInvoiceForm: {
                invoiceId: "",
                administration: "Almanakken",
            },
            invoiceToOrderForm: {
                sellerRef: "",
                orderRef: "",
                orderLine: {
                    seller_order_line_ref: "",
                    lead_time: "5",
                    price: "",
                    production_method: "Digitaal",
                    run_volume: "",
                    express_delivery: "0",
                    address_guid: "",
                    product: {
                        name: "",
                        product_json: {},
                        dimensions: {
                            width: "",
                            length: "",
                            bleed: "",
                        },
                    },
                },
                customerGuid: "",
                address: {
                    address: "",
                    postal_code: "",
                    city: "",
                    country: "",
                    name: "",
                    company: "",
                },
                shippingCosts: "",
                shopReferral: "",
                comment: "",
            },
        };
    },
    beforeMount() {
        this.setCustomerData();
        this.setOrders();
    },
    mounted() {
        this.setInputFilter(
            document.getElementById("form-shipping_costs"),
            function(value) {
                return /\d{0,4}/.test(value); // Match any number but not 0
            }
        );

        //TODO: REMOVE BELEOW LINE
        this.orderFormData = {
            address_guid: "2f66b272-2198-4f2e-965b-71997a1e3b1a",
        }; //REMOVE
    },
    watch: {
        orderFormData: function() {
            // if(this.orderFormData){
            document
                .getElementById("add-attribute")
                .addEventListener("change", async (e) => {
                    if (this.orderlineAttributes.length > 15) return;
                    this.orderlineAttributes.push(e.target.value);
                    e.target.value = "Voeg attribuut toe";
                    await this.$nextTick(() => {
                        document
                            .getElementById("new-orderline-modal")
                            .scrollTo(0, 1000);
                    });
                });
        },
        orderlineAttributes: function() {},
    },
    methods: {
        async setCustomerData() {
            await this.getCustomer(this.customerGuid)
                .then((result) => {
                    this.customerData = result;
                })
                .catch((e) => console.log(e));
        },
        async setOrders() {
            await this.getCustomerOrders(this.customerGuid)
                .then((result) => {
                    // appends the customer data to the orders
                    for (let order of result) {
                        order.customer = this.customerData;
                    }
                    this.orders = result;
                })
                .catch((e) => console.log(e));
        },
        showNewOrderModal() {
            this.activateBlockOut();
            document.getElementById("new-order-modal").style.display = "block";
        },
        closeNewOrderModal() {
            this.deactivateBlockOut();
            document.getElementById("new-order-modal").style.display = "none";
        },
        openNewAddressModal(zIndex = 0) {
            const addressModal = document.getElementById("new-address-modal");
            if (zIndex) addressModal.style.zIndex = zIndex;
            addressModal.style.display = "block";
        },
        closeNewAddressModal() {
            // this.deactivateBlockOut();
            document.getElementById("new-address-modal").style.display = "none";
        },
        async newAddress(e) {
            const formData = new FormData(e.target);
            const addressData = {};
            for (let [index, value] of formData) {
                addressData[index] = value;
            }
            addressData.type = "Billing";
            addressData.guid = this.generateGUID();

            await this.createAddress(this.customerGuid, addressData)
                .then((result) => {
                    this.customerData.addresses.unshift(result);
                    this.closeNewAddressModal();
                })
                .catch((e) => console.log(e));
        },
        orderFormChanged() {
            const form = document.getElementById("order-form");
            const formData = new FormData(form);
            for (let [index, value] of formData) {
                this.orderFormData[index] = value;
            }
            this.orderFormData.customer_guid = this.customerGuid;
            this.orderFormData.guid = this.generateGUID();
            this.orderFormData.status = "Open";
            this.orderFormData.order_date = this.formatToSQLDate(
                new Date().toString()
            );
            this.orderFormData.orderlines = this.orderlines;
        },
        showOrderlineModal() {
            this.orderFormChanged();
            if (!this.orderFormData.seller_ref) {
                alert(
                    "De order referentie moet ingevuld zijn voordat je orderregels toevoegd."
                );
                return;
            }
            this.orderlineIncrement++;
            document.getElementById("new-orderline-modal").style.display =
                "block";
        },
        closeOrderlineModal() {
            document.getElementById("new-orderline-modal").style.display =
                "none";
        },
        async loadProductPreset(e) {
            if (e.target.value === "default") {
                this.orderlineAttributes = [
                    "Bedrukking",
                    "Materiaal",
                    "Afwerking",
                    "Veredeling",
                ];
                await this.$nextTick(() => {
                    document
                        .getElementById("new-orderline-modal")
                        .scrollTo(0, 1000);
                });
            }
        },
        newOrderline(e) {
            const formData = new FormData(e.target);
            const orderline = {};
            const product = {};
            const dimensions = {};
            const attributes = {};
            for (let [index, value] of formData) {
                if (!value) continue;
                if (index.includes("product-attribute-")) {
                    attributes[index.split("product-attribute-")[1]] = value;
                    continue;
                }
                if (index.includes("product-dimension-")) {
                    dimensions[index.split("product-dimension-")[1]] = value;
                    continue;
                }
                if (index.includes("product-name")) {
                    product["name"] = value;
                    continue;
                }
                orderline[index] = value;
            }
            product.product_json = attributes;
            product.dimensions = dimensions;
            orderline.product = product;
            orderline.guid = this.generateGUID();
            orderline.producer_guid = null;
            orderline.expected_shipping_date = this.formatToSQLDate(
                this.calculateShippingDate(orderline.lead_time)
            );
            orderline.actual_shipping_date = null;
            orderline.tracking_number = null;
            orderline.tracking_url = null;
            orderline.main_status = 1;
            orderline.printed = null;
            orderline.finished = null;
            orderline.external_handling = null;
            orderline.purchase_price = null;
            this.orderlines.push(orderline);
            this.closeOrderlineModal();
        },
        calculateShippingDate(leadTime) {
            const date = new Date();
            return date.addWeekDays(parseInt(leadTime));
        },
        async submitOrder() {
            if (!this.orderlines.length) {
                alert("At least one orderline should be created.");
                return;
            }
            this.orderFormChanged();
            await this.createOrder(this.orderFormData)
                .then(() => {
                    this.orderlines = [];
                    this.orderFormData = {};
                    this.orderlineIncrement = 0;
                    this.orderlineAttributes = [];
                    this.setOrders();
                    this.closeNewOrderModal();
                })
                .catch((e) => console.log(e));
        },
        openInvoiceOrderModal() {
            this.$refs.invoiceOrderModal.openModal();
        },
        async importInvoice(event) {
            console.log(event);
            event.preventDefault();

            console.log(this.importInvoiceForm);

            await this.apiGetRequest(
                `v1/wefact/invoice/${this.importInvoiceForm.invoiceId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Wefact-Administration": this.importInvoiceForm
                            .administration,
                    },
                }
            )
                .then((invoiceResponse) => {
                    console.log({ invoiceResponse });
                    const invoice = invoiceResponse.invoice;

                    if (!invoice) {
                        alert("Invoice not found");
                        return;
                    }

                    const productJson = {};

                    invoice.InvoiceLines.forEach((line, index) => {
                        productJson[`factuurregel-${index + 1}`] =
                            line.Description + "\n" + "Oplage: " + line.Number;
                    });

                    this.invoiceToOrderForm.sellerRef = invoice.InvoiceCode;
                    this.invoiceToOrderForm.orderRef = invoice.ReferenceNumber;
                    //this.invoiceToOrderForm.comment = invoice.Description;
                    this.invoiceToOrderForm.address.name =
                        invoice.Initials + " " + invoice.SurName;
                    this.invoiceToOrderForm.address.company =
                        invoice.CompanyName;
                    this.invoiceToOrderForm.address.address = invoice.Address;
                    this.invoiceToOrderForm.address.postal_code =
                        invoice.ZipCode;
                    this.invoiceToOrderForm.address.city = invoice.City;
                    this.invoiceToOrderForm.address.country = invoice.Country;
                    this.invoiceToOrderForm.orderLine.seller_order_line_ref =
                        invoice.InvoiceCode + "-1";
                    this.invoiceToOrderForm.orderLine.lead_time = "";
                    this.invoiceToOrderForm.orderLine.price =
                        invoice.AmountExcl;
                    this.invoiceToOrderForm.orderLine.production_method =
                        "Digitaal";
                    this.invoiceToOrderForm.orderLine.run_volume = "";
                    this.invoiceToOrderForm.orderLine.express_delivery = "0";
                    this.invoiceToOrderForm.orderLine.address_guid = "";
                    this.invoiceToOrderForm.orderLine.product.name =
                        invoice.ReferenceNumber;
                    this.invoiceToOrderForm.orderLine.product.product_json = productJson;
                    this.invoiceToOrderForm.shippingCosts = 0;
                    this.invoiceToOrderForm.shopReferral = this.importInvoiceForm.administration;
                    this.invoiceToOrderForm.comment = invoice.Description;
                    this.invoiceToOrderForm.customerGuid = this.customerGuid;
                    this.invoiceToOrderForm.orderLine.guid = this.generateGUID();
                    this.invoiceToOrderForm.guid = this.generateGUID();
                    this.invoiceToOrderForm.status = "Open";
                    this.invoiceToOrderForm.order_date = this.formatToSQLDate(
                        new Date().toString()
                    );

                    console.log(this.invoiceToOrderForm);
                })
                .catch((e) => console.log(e));
        },
        async submitInvoiceToOrder() {
            // get address guid
            const addressData = this.invoiceToOrderForm.address;
            addressData.type = "Billing";
            addressData.guid = this.generateGUID();

            const address = await this.createAddress(
                this.customerGuid,
                addressData
            );

            const expectedShippingDate = this.calculateShippingDate(
                this.invoiceToOrderForm.orderLine.lead_time
            );

            const orderBody = {
                address_guid: address.guid,
                guid: this.invoiceToOrderForm.guid,
                customer_guid: this.customerGuid,
                shop_referral: this.invoiceToOrderForm.shopReferral,
                seller_ref: this.invoiceToOrderForm.sellerRef,
                order_date: this.invoiceToOrderForm.order_date,
                status: this.invoiceToOrderForm.status,
                orderlines: [
                    {
                        seller_order_line_ref: this.invoiceToOrderForm.orderLine
                            .seller_order_line_ref,
                        lead_time: this.invoiceToOrderForm.orderLine.lead_time,
                        price: this.invoiceToOrderForm.orderLine.price,
                        production_method: this.invoiceToOrderForm.orderLine
                            .production_method,
                        run_volume: this.invoiceToOrderForm.orderLine
                            .run_volume,
                        express_delivery: this.invoiceToOrderForm.orderLine
                            .express_delivery,
                        address_guid: address.guid,
                        product: {
                            name: this.invoiceToOrderForm.orderLine.product
                                .name,
                            product_json: this.invoiceToOrderForm.orderLine
                                .product.product_json,
                            dimensions: this.invoiceToOrderForm.orderLine
                                .product.dimensions,
                        },
                        guid: this.invoiceToOrderForm.orderLine.guid,
                        expected_shipping_date: expectedShippingDate,
                        actual_shipping_date: null,
                        tracking_number: null,
                        tracking_url: null,
                        producer_guid: null,
                        purchase_price: 0,
                        main_status: 1,
                        printed: this.invoiceToOrderForm.orderLine.printed,
                        finished: this.invoiceToOrderForm.orderLine.finished,
                        external_handling: this.invoiceToOrderForm.orderLine
                            .external_handling,
                    },
                ],
            };

            console.log(JSON.stringify(orderBody, null, 2));
            const order = await this.createOrder(orderBody);
            // move to order page
            this.$router.push(`/orders/${order.guid}`);
        },
    },
};
</script>

<style>
.page-header {
    background-color: #f6f6f6;
}

.page-header h1 {
    font-size: 1.3rem;
    font-weight: bold;
}

.hiddenRow {
    padding: 0 !important;
}

.status-dot {
    height: 15px;
    width: 15px;
    background-color: #ffc107;
    border-radius: 50%;
    display: inline-block;
}

.fa-chevron-down {
    transition: all 0.2s ease-in-out;
}

.rotate-chevron {
    transform: rotate(-180deg);
}
.customer-modal {
    overflow-y: auto;
    scroll-behavior: smooth;
    z-index: 1100;
    display: none;
    position: absolute;
    top: 10vh;
    left: 35vw;
    width: 40vw;
    height: 85vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}
.orderline-modal {
    overflow-y: auto;
    display: none;
    scroll-behavior: smooth;
    z-index: 1100;
    position: absolute;
    top: 10vh;
    left: 35vw;
    width: 40vw;
    height: 85vh;
    background-color: white;
    box-shadow: grey 0 0 10px;
    border-radius: 10px;
}
.orderline-modal::-webkit-scrollbar {
    width: 10px;
}
.orderline-modal::-webkit-scrollbar-track {
    /* background: white; */
    background: #f1f1f1;
}
.orderline-modal::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}
.orderline-modal::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.import-invoice-form {
    display: flex;
    flex-direction: column;
}
#invoice-to-order-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#invoice-to-order-form textarea {
    height: 150px;
}
</style>
