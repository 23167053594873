<template>
  <sidebar-menu
    @update:collapsed="onToggleCollapse"
    :menu="menu"
    :width="width"
    :showOneChild="true"
    :disableHover="true"
  />
</template>

<script>
export default {
  name: "CustomSidebarMenu",
  data() {
    return {
      width: "16rem",
      menu: [
        {
          header: `Welkom, ${this.$auth.user.value.nickname}`,
          hiddenOnCollapse: false,
          class: "custom-header",
        },
        {
          href: { path: "/" },
          title: "Dashboard",
          icon: "fas fa-th-list",
        },
        {
          // href: { path: "/customers" },
          title: "Klanten",
          icon: "fas fa-users",
          child: [
            {
              href: "/customers",
              title: "Alle klanten",
            },
            {
              href: "/customers/create",
              title: "Nieuwe klant toevoegen",
            }
          ]
        },
        {
          title: "Orders",
          icon: "fas fa-list-ul",
          child: [
            {
              href: "/orders",
              title: "Alle orders",
            },
            {
              href: "/orders/open",
              title: "Openstaande orders",
            }
          ],
        },
        {
          href: { path: "/file/check/open" },
          title: "Files",
          icon: "far fa-file-pdf",
          child: [
            {
              href: "/file/check/open",
              title: "To check",
            },
            {
              href: "/file/check/reviewed",
              title: "Reviewed",
            },
          ],
        },
        {
          title: "Productie",
          href: {path: "/production"},
          icon: "fas fa-print",
        },
        // {
        //   title: "Planning",
        //   icon: "far fa-calendar-alt",
        // },
        // {
        //   title: "Sluitingsdagen",
        //   icon: "far fa-calendar-times",
        // },
        // {
        //   title: "Systeem status",
        //   icon: "far fa-chart-bar",
        // },
        // {
        //   title: "Instellingen",
        //   icon: "fas fa-cog",
        // },
      ],
    };
  },
  methods: {
    onToggleCollapse(collapsed) {
      if (collapsed) {
        document.getElementById("app").classList.add("nav-collapse");
      } else {
        document.getElementById("app").classList.remove("nav-collapse");
      }
    },
  },
};
</script>

<style>
#app .v-sidebar-menu .vsm--icon {
  background-color: transparent;
}

#app.nav-collapse {
  padding-left: 65px;
}

#app .v-sidebar-menu .vsm--title {
  font-size: 0.9rem;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#app .v-sidebar-menu .vsm--header {
  font-size: 0.9rem;
}

#app .v-sidebar-menu {
  background-color: #222;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* #app .v-sidebar-menu .vsm--scroll {

} */

#app .v-sidebar-menu.vsm_expanded .vsm--link_open {
  background-color: rgba(30, 30, 33, 0.5) !important;
}

#app .v-sidebar-menu .vsm--toggle-btn {
  background-color: #131315;
}

#app .v-sidebar-menu .vsm--link.vsm--link_active {
  -webkit-box-shadow: 3px 0 0 0 #fff inset;
  box-shadow: inset 3px 0 0 0 #fff;
}

#app .v-sidebar-menu .vsm--header {
  text-transform: capitalize;
  text-align: center;
  background-color: #131315;
  padding-top: 130px;
  padding-bottom: 15px;
  font-weight: normal;
}

.custom-header:before {
  content: "";
  background-color: gray;
  width: 80%;
  height: 1px;
  position: absolute;
  left: 23px;
  top: 110px;
}

.custom-header:after {
  content: "";
  width: 100%;
  height: 100px;
  background: url("../../assets/logo.png") no-repeat;
  background-size: 140px;
  top: 20px;
  right: -29px;
  position: absolute;
  display: inline-block;
}

#app.nav-collapse .v-sidebar-menu .vsm--header {
  padding-top: 43px;
  font-size: 0;
}

#app.nav-collapse .custom-header:after {
  background: url("../../assets/logo_small.png") no-repeat;
  right: -15px;
  top: 10px;
  height: 40px;
}

#app.nav-collapse .custom-header:before {
  content: none;
}

.v-sidebar-menu ::-webkit-scrollbar {
  display: none;
}
</style>