<template>
  <div>
    <page-header pageName="Klantenoverzicht" />
    <div class="container">
      <search-bar type="customer" />
      <customer-list />
    </div>
  </div>
</template>


<script>
// Import component
import CustomerList from "@/components/customer/CustomerList.vue";
import PageHeader from "@/components/PageHeader.vue";
import SearchBar from "@/components/nav/SearchBar.vue";

export default {
  name: "Customers",
  components: {
    CustomerList,
    PageHeader,
    SearchBar
  },
};
</script>



