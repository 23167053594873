<template>
  <div class="pagination-container">
    <!-- Check that the SDK client is not currently loading before accessing is methods -->
    <div class="py-3 text-end" v-if="!$auth.loading.value">
      <button :key="'page-first'" data-page="page-first" v-on:click="navigateTo(1)"><i class="fas fa-fast-backward"></i></button>
      <button :key="'page-previous'" data-page="page-previous" v-on:click="navigateTo(Math.max(currentPage - 1, 1))"><i class="fas fa-step-backward"></i></button>
      <button
        v-for="index in this.blockRange"
        :id="'page-' + index + '-' + location"
        :key="'page-' + index"
        :data-page="'page-' + index"
        v-on:click="navigateTo(index)"
      >
        {{ index }}
      </button>
      <button :key="'page-next'" data-page="page-next" v-on:click="navigateTo(Math.min(currentPage + 1, lastBlock))"><i class="fas fa-step-forward"></i></button>
      <button :key="'page-last'" data-page="page-last" v-on:click="navigateTo(lastBlock)"><i class="fas fa-fast-forward"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentBlock: "",
      lastBlock: "",
    };
  },
  beforeMount() {
    this.lastBlock = Math.ceil(this.totalRows / this.rowsPerPage);
    this.blockRange = this.calculateBlockRange();
  },
  mounted() {
    const activeButtonTop = document.querySelector(`#page-${this.currentPage}-top`);
    const activeButtonBottom = document.querySelector(`#page-${this.currentPage}-bottom`);
    if(activeButtonTop) {
      activeButtonTop.classList.add("active-button")
      }
    if(activeButtonBottom) {
      activeButtonBottom.classList.add("active-button")
      }
  },
  name: "PaginationButtons",
  props: ["totalRows", "rowsPerPage", "currentPage", "location"],
  computed: {},
  methods: {
    navigateTo(index) {
      this.$emit("paginationClick", index);
    },
    calculateLeftBlock() {
      if (this.currentPage - 1 < 2) {
        return this.currentPage - (this.currentPage - 1);
      }
      if (this.lastBlock - this.currentPage < 2) {
        return this.currentPage - (4 - (this.lastBlock - this.currentPage));
      }
      return this.currentPage - 2;
    },
    calculateRightBlock() {
      if (this.currentPage - 1 < 2) {
        return Math.min(this.currentPage + (4 - (this.currentPage - 1)), this.lastBlock);
      }
      if (this.lastBlock - this.currentPage < 2) {
        return this.lastBlock, this.lastBlock;
      }
      return Math.min(this.currentPage + 2, this.lastBlock);
    },
    calculateBlockRange() {
      if (!this.totalRows) return [];
      const blockrange = [];
      for (
        let i = this.calculateLeftBlock();
        i <= this.calculateRightBlock();
        i++
      ) {
        blockrange.push(i);
      }
      return blockrange;
    },
  },
};
</script>

<style>
.pagination-container button{
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px!important;
}
.pagination-container .active-button{
  background-color: #337ab7;
  color: white;
}
</style>