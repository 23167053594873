<template>
    <link
        rel="stylesheet"
        href="//use.fontawesome.com/releases/v5.0.7/css/all.css"
    />
    <div>
        <!-- Sidebar component -->
        <custom-sidebar-menu />

        <!-- Topbar component -->
        <topbar-menu />

        <div class="spacer"></div>
        <div id="z1050-over"></div>
        <div class="mb-5">
            <router-view />
        </div>
    </div>
</template>

<script>
// Import component
import CustomSidebarMenu from "./components/nav/CustomSidebarMenu.vue";
import TopbarMenu from "./components/nav/TopbarMenu.vue";

export default {
    components: {
        CustomSidebarMenu,
        TopbarMenu,
    },
    created: function() {
        // this.newUserCheck();
    },
    methods: {
        createUserDataPopUp() {
            this.activateBlockOut();
            const newDiv = document.createElement("div");
            newDiv.id = "new-user-popup";
            newDiv.innerHTML =
                "\
        <div class='container'>\
          <div class='row'>\
            <div class='col justify-content-center allign-items-center text-center p-5'> \
              <div>\
                Welkom voor de eerste keer in het (vernieuwde) OMS!\
                <br>\
                Zou ik je voor- en achternaam mogen alsjeblieft?\
              </div>\
              <div class='my-3'>\
                <form id='user-data-form'>\
                  <input type='text' name='first_name' placeholder='Voornaam'>\
                  <input type='text' name='last_name' placeholder='Achternaam'>\
                  <br>\
                  <button type='submit' class='my-2 btn btn-primary'>Ik wil orders managen!</button>\
                </form>\
              </div>\
            </div>\
          </div>\
        </div>\
        ";
            document.body.append(newDiv);
        },
        async newUserCheck() {
            // get user data and check if user exists
            const userEmail = this.$auth.user.value.email;

            if (
                localStorage.getItem("userData") != "undefined" &&
                localStorage.getItem("userData") != null
            ) {
                // undefined is a string because of the local storage
                if (
                    JSON.parse(localStorage.getItem("userData")).email ==
                    userEmail
                ) {
                    if (
                        Object.keys(JSON.parse(localStorage.userData)).length ==
                        4
                    ) {
                        return;
                    }
                }
            }
            const userExists = await this.getUserExists(userEmail)
                .then((result) => {
                    return result.exists;
                })
                .catch((e) => {
                    alert("error while checking if user exists");
                    console.log(e);
                });
            if (!userExists) {
                this.createUserDataPopUp();
                const form = document.getElementById("user-data-form");
                form.addEventListener("submit", async (e) => {
                    e.preventDefault();
                    const formData = new FormData(form);
                    const userFormData = {};
                    for (let [index, value] of formData.entries()) {
                        userFormData[index] = value;
                    }
                    userFormData["guid"] = this.generateGUID();
                    userFormData["email"] = userEmail;
                    await this.createUser(userFormData)
                        .then((result) => {
                            if (result.status != 200)
                                throw new Error("Error during user create.");
                            document.getElementById("new-user-popup").remove();
                            this.deactivateBlockOut();
                            localStorage.userData = JSON.stringify(
                                userFormData
                            );
                        })
                        .catch((e) => console.log(e));
                });
            } else {
                const userData = await this.getUserByEmail(userEmail);
                localStorage.userData = JSON.stringify(userData);
            }
        },
    },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

html,
body {
    font-family: "Open Sans", sans-serif;
}

#app {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding-left: 16rem;
}

.spacer {
    padding-top: 3em;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background-color: #131315 !important;
    border-color: #131315 !important;
    border-radius: 0 !important;

    outline: none !important;
    box-shadow: none !important;
}

.btn-danger,
.btn-danger:focus,
.btn-success,
.btn-success:focus {
    border-radius: 0 !important;

    outline: none !important;
    box-shadow: none !important;
}

.table .table-btn {
    --bs-table-accent-bg: #f0f0f0 !important;
}

.table {
    border-collapse: separate;
    border-spacing: 0 1em;
}

.table-active {
    --bs-table-accent-bg: #f6f6f6 !important;
}

th {
    font-weight: bold;
    color: #858585;
}

.primary-th {
    color: #000;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-confirm:focus {
    background-color: #131315 !important;
    background-color: #131315 !important;
    border-color: #131315 !important;
    border-radius: 0 !important;

    outline: none !important;
    box-shadow: none !important;
}

.swal2-container {
    z-index: 10000 !important;
}
#new-user-popup {
    z-index: 1100;
    position: absolute;
    left: 35vw;
    top: 30vh;
    width: 30vw;
    height: 20vh;
    box-shadow: grey 0px 0px 10px;
    background-color: whitesmoke;
    border-radius: 15px;
}
#new-user-popup input {
    border-radius: 5px;
}
#new-user-popup input::placeholder {
    text-align: center;
}
/* #new-user-popup button {
  text-align: center;
  color: #f6f6f6;
  background-color: #131315;
  border-radius: 2px;
  border: 0;
  height: 2em;
} */
#z1050-over {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1050;
}
</style>
