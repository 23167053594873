<template>
  <page-header pageName="Reviewed" />
  <div class="container-fluid px-5 my-4">
    <div class="row d-flex justify-content-start">
      <div class="cards col-3" v-for="file of files" :key="file.id">
        <div class="card mb-3 text-start">
          <div class="card-header d-inline-block">
            <p class="m-0 d-flex">
              Order-ref: {{file.id}}
              <i class="fas fa-link ms-auto my-auto"></i>
            </p>
          </div>
          <div class="card-body vld-parent text-center z-index-5">
            <div class="preview">
              <a :href="`/file/${file.guid}`">
                <canvas :id="file.id" class="card-img-top"></canvas>
              </a>
            </div>
            <small>{{file.size}}</small>
            <i :class="file.statusClass + ' ps-1'"></i>
            <loading v-model:active="file.isLoading" :can-cancel="false" :is-full-page="false" />
          </div>
          <div class="card-footer">
            <div class="d-block">
              <a
                class="btn btn-primary me-2"
                data-bs-toggle="tooltip"
                title="Open file in tab"
                :href="file.url"
                target="_BLANK"
              >
                <i class="fas fa-external-link-alt ms-auto my-auto"></i>
              </a>
              <a
                class="btn btn-primary float-end"
                data-bs-toggle="tooltip"
                title="Reopen file"
                @click="reopenFile(file.guid)"
              >
                <i class="fas fa-undo-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// Import axios
import axios from "axios";

import PageHeader from "@/components/PageHeader.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "CheckReviewed",
  components: { PageHeader, Loading },
  data() {
    return {
      files: [],
    };
  },

  mounted() {
    this.getFiles();
  },

  methods: {
    async getFiles() {
      await axios
        .get("v1/file/reviewed")
        .then((response) => {
          this.files = response.data;
          this.files.forEach((file) => {
            file.path = file.path.split("/")[1];
            file.isLoading = true;
          });
        })
        .then(() => {
          this.files.forEach(async (file) => {
            let canvas = document.getElementById(file.id);
            await this.renderPreview(file, canvas)
              .then((file_size) => {
                file.isLoading = false;
                file.size = file_size;

                file.statuses[0].status == "Accepted"
                  ? (file.statusClass = "fas fa-check text-success")
                  : (file.statusClass = "fas fa-times text-danger");
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async reopenFile(guid) {
      this.$swal({
        icon: "question",
        title: `Reopening file`,
        text: guid,

        input: "text",
        inputLabel: "Opmerking",
        inputValidator: (value) => {
          if (!value) {
            return "Comment can not be empty";
          }
        },

        confirmButtonText: "Reopen",
        showCancelButton: true,
      }).then(async (data) => {
        if (data.value) {
          await axios
            .post(`/v1/file/${guid}/reopen`, {
              comment: data.value,
            })
            .then(() => {
              this.getFiles();
            });
        }
      });
    },
  },
};
</script>

<style>
.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: scale-down;
}

.card-body {
  background-color: rgba(0, 0, 0, 0.03);
  height: 18vw;
  width: 100%;
}

.card-header,
.card-footer {
  background-color: rgb(255, 255, 255) !important;
}
</style>