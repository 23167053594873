<!-- All orders page - orderline accordion -->
<template>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive-xl">
                <table
                    class="table table-borderless text-start"
                    aria-describedby="OrdersList"
                >
                    <thead>
                        <tr class="text-uppercase">
                            <th :style="{ width: columnWidths[0] + '%' }" scope="col">Ordnr</th>
                            <th :style="{ width: columnWidths[1] + '%' }" scope="col">Verkoper</th>
                            <th :style="{ width: columnWidths[2] + '%' }" scope="col">Bedrijf</th>
                            <th :style="{ width: columnWidths[3] + '%' }" scope="col">Ontvanger</th>
                            <th :style="{ width: columnWidths[4] + '%' }" scope="col">Ref</th>
                            <th :style="{ width: columnWidths[5] + '%' }" scope="col">Klant</th>
                            <th :style="{ width: columnWidths[6] + '%' }" scope="col">Besteldatum</th>
                            <th :style="{ width: columnWidths[7] + '%' }" scope="col">Aantal</th>
                            <th :style="{ width: columnWidths[8] + '%' }" scope="col">Status</th>
                            <th :style="{ width: columnWidths[9] + '%' }" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody id="order-list-body">
                        <template v-for="order in orders" :key="order.id">
                            <tr
                                data-bs-toggle="collapse"
                                :data-bs-target="'#order_line_' + order.id"
                                v-on:click="loadFile(order)"
                                class="accordion-toggle collapsed table-active"
                            >
                                <td>
                                    <router-link
                                        class="d-flex text-decoration-none"
                                        :to="{
                                            name: 'OrderDetail',
                                            params: {
                                                guid: order.guid,
                                            },
                                        }"
                                    >
                                        <p>{{ order.seller_ref }}</p>
                                    </router-link>
                                </td>
                                <td>{{ order.customer.referral }}</td>
                                <td>{{ order.address.company }}</td>
                                <td>
                                    {{ order.orderlines[0].address.company }}
                                </td>
                                <td>{{ order.orderlines[0].product.name }}</td>
                                <td>{{ order.customer.name }}</td>
                                <td>{{ parseDate(order.order_date) }}</td>
                                <!-- <td class="fw-bold">
                                    <status-dot :status="order.status" />
                                </td> -->
                                <td>{{ order.orderlines.length }}</td>
                                <td>
                                    {{
                                        order.orderlines.filter(
                                            (ol) => ol.main_status > 5
                                        ).length === order.orderlines.length
                                            ? "Afgerond"
                                            : "Open"
                                    }}
                                </td>
                                <td class="text-center td-end">
                                    <i class="fas fa-chevron-down"></i>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="12" class="hiddenRow">
                                    <div
                                        v-for="orderline in order.orderlines"
                                        :key="orderline.id"
                                        class="accordian-body collapse"
                                        :id="'order_line_' + order.id"
                                    >
                                        <div
                                            class="production-status align-self-center"
                                        >
                                            <p
                                                v-if="orderline.statuses.length"
                                                class="m-0"
                                            >
                                                <span
                                                    style="background-color: #ffc107"
                                                    class="status-dot mx-3"
                                                ></span>
                                                {{
                                                    orderline.statuses[0].status
                                                }}
                                            </p>
                                            <p class="m-0" v-else>
                                                <span
                                                    style="background-color: #d6d5d5"
                                                    class="status-dot mx-3"
                                                ></span
                                                >Onbekend
                                            </p>
                                        </div>
                                        <div class="file align-self-center">
                                            <div
                                                v-if="orderline.files.length"
                                                class="preview-file"
                                            >
                                                <canvas
                                                    class="card-img-top border"
                                                    :id="orderline.files[0].id"
                                                ></canvas>
                                                <!-- removed because of warning -->
                                                <!-- <loading
                          v-model:active="orderline.files[0].isLoading"
                          :can-cancel="false"
                          :is-full-page="false"
                        /> -->
                                                <div
                                                    class="buttons d-flex justify-content-center"
                                                >
                                                    <a
                                                        class="btn btn-primary"
                                                        :href="
                                                            orderline.files[0]
                                                                .url
                                                        "
                                                        target="_blank"
                                                    >
                                                        <i
                                                            class="fas fa-eye"
                                                        ></i>
                                                    </a>
                                                    <a
                                                        class="btn btn-primary ms-3"
                                                        v-on:click="
                                                            downloadFile(
                                                                orderline
                                                                    .files[0]
                                                                    .url,
                                                                orderline
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fas fa-download"
                                                        ></i>
                                                    </a>
                                                    <a
                                                        class="btn btn-primary ms-3"
                                                    >
                                                        <i
                                                            class="fas fa-upload"
                                                            v-on:click="
                                                                uploadFile()
                                                            "
                                                        ></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="text-center" v-else>
                                                <canvas
                                                    class="card-img-top border"
                                                ></canvas>
                                                <span
                                                    >Geen bestand gevonden</span
                                                >
                                            </div>
                                        </div>
                                        <div
                                            v-if="orderline.product"
                                            class="product"
                                        >
                                            <div>
                                                <h4 class="fw-bold">
                                                    {{ orderline.product.name }}
                                                </h4>
                                                <p
                                                    class="m-0"
                                                    v-for="(value,
                                                    key) in orderline.product
                                                        .product_json"
                                                    :key="value"
                                                >
                                                    <span
                                                        class="fw-bold text-capitalize"
                                                        >{{ key }}:</span
                                                    >
                                                    <span class="float-end">{{
                                                        value
                                                    }}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="align-self-center" v-else>
                                            Geen product gevonden
                                        </div>
                                        <div
                                            v-if="orderline.address"
                                            class="address align-self-start"
                                        >
                                            <h4 class="fw-bold">Bezorgadres</h4>
                                            <p>{{ orderline.address.name }}</p>
                                            <p>
                                                {{ orderline.address.company }}
                                            </p>
                                            <p>
                                                {{ orderline.address.address }}
                                            </p>
                                            <p>
                                                {{
                                                    orderline.address
                                                        .postal_code
                                                }}
                                                {{ orderline.address.city }} ({{
                                                    orderline.address.country
                                                }})
                                            </p>
                                        </div>
                                        <div class="align-self-center" v-else>
                                            Geen address gevonden
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// import StatusDot from "@/components/StatusDot.vue";
export default {
    components: {
        // StatusDot,
    },
    name: "OrderList",
    props: {
        orders: Array,
    },
    data() {
        return {
            search: "".toLowerCase(),
            sellers: [],
            columnWidths: [10, 12, 12, 12, 12, 12, 12, 6, 6, 6] // Define your widths as percentages
        };
    },
    beforeMount() {},
    computed: {
        reversedItems() {
            return [...this.orders].reverse();
        },
        filteredOrders() {
            if (!this.search.length) {
                return this.orders;
            } else {
                return [
                    ...this.orders.filter((o) =>
                        o.customer.referral.toLowerCase().includes(this.search)
                    ),
                ];
            }
            // console.log(this.orders[0])
            // console.log(this.orders.filter(o => this.search.match(o.seller_ref)));
            //  return [...this.orders.filter(o => this.search.match(o.seller_ref))]
        },
        // filteredList() {
        //   const value = this.search.charAt(0).toUpperCase() + this.search.slice(1);
        //   console.log(value);
        //   return this.orders.filter(function (order) {
        //     return (
        //       order.comment.indexOf(value) > -1 ||
        //       order.wefact_ref.indexOf(value) > -1
        //     );
        //   });
        // },
        filterSellers() {
            if (!this.sellers.length) {
                return this.orders;
            } else {
                return [
                    ...this.orders.filter((o) =>
                        this.sellers.includes(o.customer.referral)
                    ),
                ];
            }
        },
    },

    methods: {
        loadFile(order) {
            order.orderlines.forEach(async (orderline) => {
                orderline.files.forEach(async (file) => {
                    let canvas = document.getElementById(file.id);
                    if (!file.loaded && canvas) {
                        await this.renderFile(file, canvas);
                    }
                });
            });
        },
        async renderFile(file, canvas) {
            await this.renderPreview(file, canvas)
                .then((file_size) => {
                    file.size = file_size;
                    file.isLoading = false;
                })
                .catch((err) => {
                    this.errorModal(err);
                })
                .finally(() => (file.loaded = true));
        },
    },
};
</script>

<style>
.page-header {
    background-color: #f6f6f6;
}

.td-end {
    --bs-table-accent-bg: #f0f0f0 !important;
}

.accordion-toggle:hover {
    cursor: pointer;
}

.page-header h1 {
    font-size: 1.3rem;
    font-weight: bold;
}

.hiddenRow {
    padding: 0 !important;
}

.fa-chevron-down {
    transition: all 0.3s ease;
}

.accordion-toggle.collapsed .fa-chevron-down {
    transform: rotate(-180deg);
}

.address p {
    margin: 0;
}

.accordian-body {
    padding-top: 15px;
    margin-top: 15px;
}

.accordian-body > div {
    margin: 0 30px;
    flex: 1;
}

.accordian-body .production-status {
    margin: 0;
    flex: 0 0 130px;
}

.accordian-body .file {
    height: 100%;
}

.accordian-body:nth-child(2n) {
    background-color: #f6f6f6;
}

.accordian-body {
    display: flex;
}

.accordian-body.collapsing {
    display: flex;
}
.accordian-body.show {
    display: flex;
}

/* to fix shifting bug when opening order accordion*/
.accordion-toggle td {
    max-width: 1em;
}

.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: scale-down;
}

.product p {
    font-size: 0.9rem;
}

.file .buttons .btn {
    white-space: nowrap;
}

td a p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
td {
    vertical-align: middle;
}
</style>
