<template>
  <page-header pageName="Bestand uploaden" />
  <div class="container">
    <loading v-model:active="loading" :can-cancel="false" :is-full-page="false" />
    <div class="row justify-content-center">
      <div class="col-8">
        <h1 class="mt-4">Upload een bestand</h1>
        <form class="mb-4" @submit.prevent="submitFile()">
          <div class="mb-4">
            <label for="guid" class="form-label">GUID</label>
            <input type="text" class="form-control" disabled v-model="formData.guid" />
          </div>
          <div class="mb-4">
            <label for="order_line_guid" class="form-label">Order line GUID</label>
            <input type="text" class="form-control" required v-model="formData.order_line_guid" />
          </div>
          <div class="mb-4">
            <label for="version" class="form-label">Versie</label>
            <input type="number" class="form-control" required v-model="formData.version" />
          </div>
          <div class="mb-4">
            <label for="file" class="form-label">PDF Bestand</label>
            <input
              class="form-control"
              type="file"
              ref="file"
              accept=".pdf"
              required
              v-on:change="onFileUpload()"
            />
          </div>
          <button ref="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { PageHeader, Loading },
  name: "FileCreate",

  data() {
    return {
      formData: {
        guid: this.generateGUID(),
        order_line_guid: null,
        version: null,
        file: null,
      },

      loading: false,
    };
  },

  methods: {
    onFileUpload() {
      this.formData.file = this.$refs.file.files[0];
    },

    submitFile() {
      // Create form data for request
      let data = new FormData();
      data.append("guid", this.formData.guid);
      data.append("order_line_guid", this.formData.order_line_guid);
      data.append("version", this.formData.version);
      data.append("pdf_file", this.formData.file);

      // Enable loader spinner
      this.loading = true;

      axios
        .post("/v1/file", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "json",
        })
        .then(() => {
          window.location.replace("/");
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;

          this.$swal({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
            confirmButtonText: "Cancel",
          });
        });
    },
  },
};
</script>

<style>

</style>
