<template>
    <div class="dashboard">
        <page-header pageName="Dashboard" />
        <div class="container">
            <div class="orderline-list">
                <h2>Vandaag voor productie</h2>
                <div
                    v-if="
                        productionLinesForToday &&
                            productionLinesForToday.length
                    "
                >
                    <div class="accordion">
                        <div
                            class="accordion-item"
                            v-for="(orderLine,
                            index) in productionLinesForToday"
                            :key="orderLine.guid"
                        >
                            <orderline
                                :orderline="orderLine"
                                :index="index"
                                :totalOrderlines="
                                    productionLinesForToday.length
                                "
                                :isOrderDetail="true"
                                :isProductionDetail="true"
                            />
                        </div>
                    </div>
                </div>
                <div v-else class="mt-5">
                    <h4>Geen orders voor vandaag</h4>
                </div>
            </div>
            <div class="orderline-list">
                <div v-if="orderLinesFileCheck && orderLinesFileCheck.length">
                    <h2>Bestanden controleren</h2>
                    <div class="accordion">
                        <div
                            class="accordion-item"
                            v-for="(orderLine, index) in orderLinesFileCheck"
                            :key="orderLine.guid"
                        >
                            <orderline
                                :orderline="orderLine"
                                :index="index"
                                :totalOrderlines="orderLinesFileCheck.length"
                                :isOrderDetail="true"
                                :isProductionDetail="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="orderline-list">
                <div
                    v-if="
                        orderLinesExternalHandling &&
                            orderLinesExternalHandling.length
                    "
                >
                    <h2>Externe afwerking</h2>
                    <div class="accordion">
                        <div
                            class="accordion-item"
                            v-for="(orderLine,
                            index) in orderLinesExternalHandling"
                            :key="orderLine.guid"
                        >
                            <orderline
                                :orderline="orderLine"
                                :index="index"
                                :totalOrderlines="
                                    orderLinesExternalHandling.length
                                "
                                :isOrderDetail="true"
                                :isProductionDetail="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="orderline-list">
                <h2>Open orders (max 5)</h2>
                <button
                    class="btn btn-primary"
                    @click="this.$router.push('/orders/open')"
                >
                    Zie alle {{ this.totalOpenOrderCount }}
                </button>
                <div v-if="openOrders && openOrders.length">
                    <order-list
                        id="order-list"
                        :key="this.openOrderListKey"
                        :orders="this.openOrders"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Orderline from "../components/order/Orderline.vue";
import PageHeader from "../components/PageHeader.vue";
import OrderList from "@/components/order/OrderList.vue";
import axios from "axios";

export default {
    components: { PageHeader, Orderline, OrderList },
    data() {
        return {
            productionLinesForToday: [],
            orderLinesFileCheck: [],
            orderLinesExternalHandling: [],
            openOrders: [],
            totalOpenOrderCount: 0,
            openOrderListKey: 1,
        };
    },
    name: "Dashboard",
    async beforeMount() {
        this.getProductionLinesForToday();
        this.getOrderLinesFileCheck();
        this.getOrderLinesExternalHandling();
        this.getOpenOrders();
    },
    methods: {
        getProductionLinesForToday: function() {
            this.getOrderLines({
                main_status: 5,
                expected_shipping_date: new Date().toISOString().split("T")[0],
                producer_guid: "1b67fe71-9dda-488f-a182-99839cd2fc9a",
                limit: 5,
            }).then((response) => {
                console.log({ response });
                this.productionLinesForToday = response;
            });
        },
        getOrderLinesFileCheck: function() {
            this.getOrderLines({
                main_status: 3,
                limit: 5,
            }).then((response) => {
                this.orderLinesFileCheck = response;
            });
        },
        getOrderLinesExternalHandling: function() {
            this.getOrderLines({
                main_status: 5,
                external_handling: "!null",
                limit: 5,
            }).then((response) => {
                this.orderLinesExternalHandling = response;
            });
        },
        getOpenOrders: async function() {
            await axios
                .get(`v1/order/open`, {
                    params: { offset: 0, limit: 5 },
                })
                .then(async (response) => {
                    for (const order of response.data) {
                        await this.getCustomerByGuid(order.customer_guid).then(
                            (customer) => {
                                order.customer = customer;
                            }
                        );
                    }
                    this.totalOpenOrderCount = Number(
                        response.headers["x-total-count"]
                    );
                    this.openOrders = response.data;
                });
        },
    },
};
</script>
<style>
.graph {
    height: 1em;
    width: 1em !important;
    background-color: #1417ce;
    border-radius: 100%;
    font-size: 20em;
    color: white;
    line-height: 1em;
}

.orderline-list {
    margin-top: 2em;
    padding: 1em;
    border: 5px solid lightgrey;
}
</style>
