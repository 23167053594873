<template>
  <div class="file">
    <page-header pageName="Bestandinformatie" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-3 mt-3 mx-4">
          <div class="vld-parent mb-2">
            <canvas class="preivew-canvas" id="c_id"></canvas>
            <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="false" />
          </div>
          <small>{{file.path}}</small>
        </div>
        <div class="col-8 mt-2 text-start">
          <div class="d-flex">
            <h1 class="d-inline-block">File</h1>
            <i
              class="fas fa-ellipsis-h d-inline-block my-auto ms-auto"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></i>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <a class="dropdown-item" href="#">Accept</a>
              </li>
              <li>
                <a class="dropdown-item" :href="file.url" target="__BLANK">Download</a>
              </li>
              <li>
                <a class="dropdown-item text-danger">Decline</a>
              </li>
            </ul>
          </div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lobortis, ex ut ullamcorper tincidunt, nunc velit ultricies urna, vitae finibus orci leo eget ex. Quisque et placerat nisl. Aenean consequat sodales felis ac scelerisque. Ut maximus tempus libero vel iaculis. Sed sed leo velit. Phasellus vel laoreet metus. Donec fermentum gravida leo ut consectetur.</p>
          <div class="status-table mt-5">
            <h2>Status</h2>
            <div class="file-content">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Comment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="status in file.statuses" :key="status.id">
                    <th scope="row">{{ status.status }}</th>
                    <td>{{ status.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { PageHeader, Loading },
  name: "FileDetail",

  data() {
    return {
      file: {
        path: "",
        lw: "",
      },

      isLoading: true,
      color: "#323232",
    };
  },

  mounted() {
    this.getFile().then(() => {
      this.isLoading = false;
      //this.getOrderForFile(this.file.order_line_id).then((order) =>
      //   console.log(order)
      // );
    });
  },

  methods: {
    // Get All files
    // Not used?
    async getFile() {
      await axios.get(`v1/file/${this.$route.params.id}`).then(async (resp) => {
        this.file = resp.data;
        let canvas = document.getElementById("c_id");

        await this.renderPreview(resp.data, canvas).then((page_size) => {
          this.isLoading = false;
          canvas.style.border = "1px solid";
          this.file.lw = `${page_size[2]}x${page_size[3]}px`;
        });
      });
    },

    async getOrderForFile(id) {
      await axios.get(`v1/order/${id}`).then(async (resp) => {
        this.order = resp.data;
      });
    },

    // async downloadFile() {
    //   // await fetch(this.file.url).then((data) => {
    //   //   data.blob().then((blob) => {
    //   //     const url = window.URL.createObjectURL(blob);
    //   //     const a = document.createElement("a");

    //   //     a.style.display = "none";
    //   //     a.href = url;
    //   //     a.download = this.file.path.split("/")[1];

    //   //     document.body.appendChild(a);
    //   //     a.click();
    //   //     document.body.removeChild(a);

    //   //     window.URL.revokeObjectURL(url);
    //     });
    //   });
    // },
  },
};
</script>

<style>
#c_id {
  width: 100%;
  height: auto;
  min-height: 60vh;
}
</style>