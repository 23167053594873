<template>
    <page-header pageName="To Check" />
    <div class="container-fluid px-5 my-4">
        <div class="row d-flex justify-content-start">
            <div class="cards col-3" v-for="file of files" :key="file.id">
                <div class="card mb-3 text-start">
                    <div class="card-header d-inline-block">
                        <p class="m-0 d-flex">
                            File ID: {{ file.id }}
                            <i class="fas fa-link ms-auto my-auto"></i>
                        </p>
                    </div>
                    <div class="card-body vld-parent">
                        <div class="preview">
                            <a :href="`/file/${file.guid}`">
                                <canvas
                                    :id="file.id"
                                    class="card-img-top"
                                ></canvas>
                            </a>
                        </div>
                        <p class="text-center" style="font-size: 0.8rem">
                            {{ file.size }}
                        </p>
                        <loading
                            v-model:active="file.isLoading"
                            :can-cancel="false"
                            :is-full-page="false"
                        />
                    </div>
                    <div class="card-footer">
                        <div class="d-block">
                            <a
                                class="btn btn-primary me-2"
                                data-bs-toggle="tooltip"
                                title="Open file in tab"
                                :href="file.url"
                                target="_BLANK"
                            >
                                <i
                                    class="fas fa-external-link-alt ms-auto my-auto"
                                ></i>
                            </a>
                            <div class="float-end">
                                <a
                                    class="btn btn-danger me-2"
                                    data-bs-toggle="tooltip"
                                    title="Force accept file"
                                    @click="forceAcceptFile(file.guid)"
                                >
                                    <i class="fas fa-wrench"></i>
                                </a>
                                <a
                                    class="btn btn-danger me-2"
                                    data-bs-toggle="tooltip"
                                    title="Decline file"
                                    @click="declineFile(file.guid)"
                                >
                                    <i class="fas fa-times"></i>
                                </a>
                                <a
                                    class="btn btn-success"
                                    data-bs-toggle="tooltip"
                                    title="Accept file"
                                    @click="acceptFile(file.guid)"
                                >
                                    <i class="fas fa-check"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Import axios
import axios from "axios";

// Import page header
import PageHeader from "@/components/PageHeader.vue";

// Import loader overlay
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    name: "CheckOpen",
    components: { PageHeader, Loading },
    data() {
        return {
            files: [],
            orderlineGuids: {},
        };
    },

    mounted() {
        this.getFiles();
    },

    methods: {
        // Get all files
        async getFiles() {
            await axios
                .get(`v1/file/open`)
                .then((response) => {
                    this.files = response.data;
                    this.files.forEach((file) => {
                        file.path = file.path.split("/")[1];
                        file.isLoading = true;
                        this.orderlineGuids[file.guid] = file.order_line_guid;
                    });
                })
                .then(() => {
                    this.files.forEach(async (file) => {
                        let canvas = document.getElementById(file.id);
                        await this.renderPreview(file, canvas)
                            .then((file_size) => {
                                file.size = file_size;
                                file.isLoading = false;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        forceAcceptFile(guid) {
            this.$swal({
                icon: "success",
                title: "Forced: Accepting file",
                text: guid,

                input: "text",
                inputLabel: "Comment",
                inputValue: "Dit bestand is geforceerd goedgekeurd.",
                inputValidator: (value) => {
                    if (!value) {
                        return "Comment can not be empty";
                    }
                },

                confirmButtonText: "Accept",
                showCancelButton: true,
            }).then(async (data) => {
                if (data.value) {
                    await axios
                        .post(`/v1/file/${guid}/force-accept`, {
                            comment: data.value,
                        })
                        .then(() => {
                            this.updateOrderlineStatus(
                                this.orderlineGuids[guid],
                                {
                                    status: "Bestand goedgekeurd",
                                    comment: data.value,
                                }
                            ).then(() => {
                                this.updateOrderline(
                                    this.orderlineGuids[guid],
                                    {
                                        main_status: 4, //Ready for outsourcing
                                    }
                                );
                            });
                            this.getFiles();
                        });
                }
            });
        },

        acceptFile(guid) {
            this.$swal({
                icon: "success",
                title: "Accepting file",
                text: guid,

                input: "text",
                inputLabel: "Comment",
                inputValue: "Dit bestand is goedgekeurd.",
                inputValidator: (value) => {
                    if (!value) {
                        return "Comment can not be empty";
                    }
                },

                confirmButtonText: "Accept",
                showCancelButton: true,
            }).then(async (data) => {
                if (data.value) {
                    await axios
                        .post(`/v1/file/${guid}/accept`, {
                            comment: data.value,
                        })
                        .then(() => {
                            this.updateOrderlineStatus(
                                this.orderlineGuids[guid],
                                {
                                    status: "Bestand goedgekeurd",
                                    comment: data.value,
                                }
                            ).then(() => {
                                this.updateOrderline(
                                    this.orderlineGuids[guid],
                                    {
                                        main_status: 4, //Ready for outsourcing
                                    }
                                );
                            });
                            this.getFiles();
                        });
                }
            });
        },

        declineFile(guid) {
            this.$swal({
                icon: "error",
                title: "Declining file",
                text: guid,

                input: "text",
                inputLabel: "Opmerking",
                inputValidator: (value) => {
                    if (!value) {
                        return "Comment can not be empty";
                    }
                },

                confirmButtonText: "Decline",
                showCancelButton: true,
            }).then(async (data) => {
                if (data.value) {
                    await axios
                        .post(`/v1/file/${guid}/decline`, {
                            comment: data.value,
                        })
                        .then(() => {
                            this.updateOrderlineStatus(
                                this.orderlineGuids[guid],
                                {
                                    status: "Bestand afgekeurd",
                                    comment: data.value,
                                }
                            ).then(() => {
                                this.updateOrderline(
                                    this.orderlineGuids[guid],
                                    {
                                        main_status: 2, // File rejected
                                    }
                                );
                            });
                            this.getFiles();
                        });
                }
            });
        },
    },
};
</script>

<style>
.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: scale-down;
}

.card-body {
    background-color: rgba(0, 0, 0, 0.03);
    height: 18vw;
    width: 100%;
}

.card-header,
.card-footer {
    background-color: rgb(255, 255, 255) !important;
}
</style>
