<template>
    <div class="order">
        <page-header pageName="Orderoverzicht" />
        <div class="py-5" v-if="errored">
            <p>
                Het spijt ons, we kunnen deze informatie momenteel niet ophalen,
                probeer het later opnieuw
            </p>
        </div>
        <div v-else class="container-fluid px-5">
            <order-list
                id="order-list"
                :key="this.orderListKey"
                :orders="this.orders"
            />
            <div class="d-flex pt-3 moon-loader-container">
                <moon-loader
                    :key="moonLoaderKey"
                    class="mx-auto"
                    :loading="loading"
                    :color="color"
                ></moon-loader>
            </div>
        </div>
    </div>
</template>

<script>
// import axios
import axios from "axios";
import OrderList from "@/components/order/OrderList.vue";
import PageHeader from "@/components/PageHeader.vue";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
    name: "OrderOpen",
    components: {
        PageHeader,
        OrderList,
        MoonLoader,
    },
    data() {
        return {
            totalOrderCount: 0,
            orders: [],
            errored: false,
            orderListKey: 1,
            moonLoaderKey: 1,
            errorMsg: "",
            loading: true,
            color: "#131315",
            searchQuery: "",
        };
    },
    async beforeMount() {
        await this.getOpenOrders();
    },
    methods: {
        async getOpenOrders() {
            await axios
                .get(`v1/order/open`, {
                    params: { offset: 0, limit: 100 },
                })
                .then(async (response) => {
                    for (const order of response.data) {
                        await this.getCustomerByGuid(order.customer_guid).then(
                            (customer) => {
                                order.customer = customer;
                            }
                        );
                    }
                    this.totalOrderCount = Number(
                        response.headers["x-total-count"]
                    );
                    this.orders = response.data;
                })
                .catch((err) => {
                    this.errored = true;
                    this.errorModal(err);
                })
                .finally(() => {
                    document.getElementById("order-list-body").style.display =
                        "table-row-group";
                    this.loading = false;
                });
        },
    },
};
</script>

<style>
html {
    overflow-y: scroll;
}
.order #order-list {
    min-height: 100vh;
}
.moon-loader-container {
    position: relative;
    bottom: 80vh;
}
</style>
