<template>
    <page-header pageName="Nieuwe Klant" />
    <div class="container-fluid px-5 my-4">
        <div class="row mb-2 d-flex justify-content-start">
            <div class="col-md-2 text-start">
                <button class="btn btn-primary" @click="showImportModal">
                    Importeer Debiteur
                </button>
                <modal-component ref="modalComponent">
                    <h1>Importeer Debiteur</h1>
                    <form
                        class="import-debtor-form"
                        @submit="importDebtor($event)"
                    >
                        <label for="administration">
                            Administratie
                        </label>
                        <select id="administration">
                            <option value="Almanakken" active
                                >Almanakken</option
                            >
                            <option value="OCC Crossmedia B.V." active
                                >OCC Crossmedia BV</option
                            >
                        </select>
                        <label for="debtorId"
                            >Debiteur ID
                            <br />
                            <small>Deze vind je in de url</small></label
                        >
                        <input type="text" id="debtorId" />
                        <button type="submit" class="btn btn-primary">
                            Importeer
                        </button>
                    </form>
                </modal-component>
            </div>
        </div>
        <div class="row d-flex justify-content-start">
            <div class="col-8 text-start">
                <form @submit.prevent="newCustomer($event)">
                    <div><h2>Klantgegevens:</h2></div>
                    <div class="mb-3">
                        <label for="company" class="form-label">Bedrijf</label>
                        <input
                            type="text"
                            v-model="formData.company"
                            required
                            class="form-control"
                            name="company"
                            id="company"
                            aria-describedby="sub-company"
                        />
                        <div id="sub-company" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="name" class="form-label">Naam</label>
                        <input
                            type="text"
                            v-model="formData.name"
                            required
                            class="form-control"
                            name="name"
                            id="name"
                            aria-describedby="sub-name"
                        />
                        <div id="sub-name" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="shop" class="form-label">Shop</label>
                        <input
                            type="text"
                            v-model="formData.referral"
                            required
                            class="form-control"
                            name="referral"
                            id="shop"
                            aria-describedby="sub-shop"
                        />
                        <div id="sub-shop" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input
                            type="email"
                            v-model="formData.email"
                            required
                            class="form-control"
                            name="email"
                            id="email"
                            aria-describedby="sub-email"
                        />
                        <div id="sub-email" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="phonenumber" class="form-label"
                            >Telefoonnummer</label
                        >
                        <input
                            type="text"
                            v-model="formData.phonenumber"
                            required
                            class="form-control"
                            name="phonenumber"
                            id="phonenumber"
                            aria-describedby="sub-phonenumber"
                        />
                        <div id="sub-phonenumber" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="debtor" class="form-label"
                            >Exact debiteurennummmer (eventueel)</label
                        >
                        <input
                            type="text"
                            v-model="formData.debtorNumber"
                            class="form-control"
                            name="debtor"
                            id="debtor"
                            aria-describedby="sub-debtor"
                        />
                        <div id="sub-debtor" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div><h2>Adres:</h2></div>
                    <div class="mb-3">
                        <label for="address-address" class="form-label"
                            >Adres</label
                        >
                        <input
                            type="text"
                            v-model="formData.address.address"
                            required
                            class="form-control"
                            name="address-address"
                            id="address-address"
                            aria-describedby="sub-address-address"
                        />
                        <div id="sub-address-address" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="address-postal_code" class="form-label"
                            >Postcode</label
                        >
                        <input
                            type="text"
                            v-model="formData.address.postal_code"
                            required
                            class="form-control"
                            name="address-postal_code"
                            id="address-postal_code"
                            aria-describedby="sub-address-postal_code"
                        />
                        <div id="sub-address-postal_code" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="address-city" class="form-label"
                            >Stad</label
                        >
                        <input
                            type="text"
                            v-model="formData.address.city"
                            required
                            class="form-control"
                            name="address-city"
                            id="address-city"
                            aria-describedby="sub-address-city"
                        />
                        <div id="sub-address-city" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="address-country" class="form-label"
                            >Land</label
                        >
                        <select
                            class="form-select"
                            v-model="formData.address.country"
                            name="address-country"
                            id="address-country"
                        >
                            <option selected value="NL">Nederland</option>
                            <option value="BE">België</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="address-name" class="form-label"
                            >Naam</label
                        >
                        <input
                            placeholder="Laat leeg als naam hetzeflde als hierboven is"
                            v-model="formData.name"
                            type="text"
                            class="form-control"
                            name="address-name"
                            id="address-name"
                            aria-describedby="sub-address-name"
                        />
                        <div id="sub-address-name" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="address-company" class="form-label"
                            >Bedrijf</label
                        >
                        <input
                            placeholder="Laat leeg als bedrijf hetzeflde als hierboven is"
                            type="text"
                            v-model="formData.company"
                            name="address-company"
                            class="form-control"
                            id="address-company"
                            aria-describedby="sub-address-company"
                        />
                        <div id="sub-address-company" class="form-text">
                            We'll never share your text with anyone else.
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ModalComponent from "@/components/Modal.vue";
export default {
    components: {
        PageHeader,
        ModalComponent,
    },
    name: "newCustomer",
    data() {
        return {
            formData: {
                company: "",
                name: "",
                referral: "",
                email: "",
                phonenumber: "",
                debtorNumber: "",
                address: {
                    address: "",
                    postal_code: "",
                    city: "",
                    country: "",
                    name: "",
                    company: "",
                },
            },
        };
    },
    methods: {
        async newCustomer(e) {
            const formData = new FormData(e.target);
            const addressData = {};
            const customerData = {};
            for (let [index, value] of formData) {
                if (index.includes("address-")) {
                    addressData[index.split("address-")[1]] = value;
                } else {
                    customerData[index] = value;
                }
            }
            if (!addressData.name) addressData.name = customerData.name;
            if (!addressData.company)
                addressData.company = customerData.company;
            addressData.guid = this.generateGUID();
            addressData.type = "Main Billing";
            customerData.addresses = [addressData];
            customerData.guid = this.generateGUID();
            await this.createCustomer(customerData).then(() => {
                this.$router.push(`/customer/${customerData.guid}`);
                window.scrollTo(0, 0);
            });
        },
        showImportModal() {
            this.$refs.modalComponent.openModal();
        },
        async importDebtor(event) {
            event.preventDefault();
            const administration = event.target[0].value;
            const debtorId = event.target[1].value;
            await this.apiGetRequest(`v1/wefact/debtor/${debtorId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Wefact-Administration": administration,
                },
            }).then((debtorResponse) => {
                this.formData.referral = administration;
                this.formData.company = debtorResponse.debtor.CompanyName;
                this.formData.name =
                    debtorResponse.debtor.Initials +
                    " " +
                    debtorResponse.debtor.SurName;
                this.formData.email = debtorResponse.debtor.EmailAddress;
                this.formData.phonenumber =
                    debtorResponse.debtor.PhoneNumber ||
                    debtorResponse.debtor.MobileNumber;
                this.formData.debtorNumber = debtorResponse.debtor.DebtorCode;
                this.formData.address.address = debtorResponse.debtor.Address;
                this.formData.address.postal_code =
                    debtorResponse.debtor.ZipCode;
                this.formData.address.city = debtorResponse.debtor.City;
                this.formData.address.country = debtorResponse.debtor.Country;
                this.formData.address.name =
                    debtorResponse.debtor.Initials +
                    " " +
                    debtorResponse.debtor.SurName;
                this.formData.address.company =
                    debtorResponse.debtor.CompanyName;
            });
        },
    },
};
</script>
<style>
.import-debtor-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    flex-wrap: wrap;
}
.import-debtor-form label {
    margin-bottom: 0.5rem;
}
.import-debtor-form input {
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
    width: 300px;
}
.import-debtor-form select {
    margin-bottom: 1rem;
    border: 1px solid #ced4da;
    width: 300px;
}
.import-debtor-form button {
    align-self: flex-end;
}
</style>
