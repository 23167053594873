<template>
    <div class="d-flex container-fluid flex-column">
        <div class="information-top px-5 py-4 row">
            <div class="info-order col-2">
                <p class="info-title">Order</p>
                <p class="info-value">{{ this.order.seller_ref }}</p>
            </div>
            <div class="info-seller col-2">
                <p class="info-title">Verkoper</p>
                <p v-if="this.customer" class="info-value">
                    {{ this.customer.referral }}
                </p>
            </div>
            <div class="info-delivery-date col-2">
                <p class="info-title">Besteldatum</p>
                <p class="info-value">{{ this.order.order_date }}</p>
            </div>
            <!-- <div class="info-order-status col-2">
                <p class="info-title">Order status</p>
                <p class="info-value">
                    <status-dot :status="orderStatus" />
                </p>
            </div> -->
            <p class="vl"></p>
            <div class="bulk-jobs col">
                <p></p>
            </div>
        </div>
        <div class="row info-back">
            <div class="text-start" v-on:click="$router.back()">
                <i class="fas fa-chevron-left p-3"></i>
            </div>
        </div>
        <div v-if="this.loaded" class="row px-5">
            <div
                v-if="this.customer"
                class="order-info text-start d-flex flex-wrap row"
            >
                <div class="customer-info col-2">
                    <h4>Klant gegevens</h4>
                    <p>{{ this.customer.name }}</p>
                    <p>{{ this.customer.phonenumber }}</p>
                    <p>{{ this.customer.email }}</p>
                </div>
                <div class="billing-info col-2">
                    <h4>Factuuradres</h4>
                    <div>
                        <div>
                            <p>{{ this.order.address.company }}</p>
                            <p>{{ this.order.address.name }}</p>
                            <p>{{ this.order.address.address }}</p>
                            <p>
                                {{ this.order.address.postal_code }}
                                {{ this.order.address.city }} ({{
                                    this.order.address.country
                                }})
                            </p>
                        </div>
                    </div>
                </div>
                <div class="invoice col-2">
                    <!-- wefact ref -->
                    <h4>Factuur</h4>
                    <div v-if="this.order.wefact_ref">
                        <!-- <a v-on:click.prevent="showResponder(responder)">> -->
                        <p>#{{ this.order.wefact_ref }}</p>
                        <!-- </a> -->
                    </div>
                </div>
                <div class="status-payment col-2">
                    <h4>Status betaling</h4>
                    <div v-if="this.order.payment_ref">
                        <status-dot :status="'Voldaan'" />
                    </div>
                    <div v-else>
                        <status-dot :status="'Wachten op betaling'" />
                    </div>
                </div>
                <div class="col">
                    <div class="w-100 d-flex mb-2">
                        <h4 class="d-inline">Interne notities:</h4>
                        <!-- <h5>Coming soon</h5> -->
                        <span class="ms-auto">
                            <!-- <button class="btn btn-primary mx-2"><i class="fas fa-eye"></i></button> -->
                            <button
                                @click="showCommentModal"
                                class="btn btn-primary ms-2"
                            >
                                <i class="fas fa-edit"></i>
                            </button>
                        </span>
                    </div>
                    <div
                        v-if="this.orderComments.length"
                        class="comment-table p-3"
                    >
                        <table class="w-100">
                            <tr
                                class="border-bottom"
                                v-for="comment of this.orderComments"
                                :key="comment.id"
                            >
                                <td style="width: 60%">
                                    {{ comment.body }}
                                </td>
                                <td
                                    style="width: 20%"
                                    class="align-text-top ps-2"
                                >
                                    <strong>{{ comment.userEmail }}</strong>
                                    <br />
                                    <strong>{{
                                        formatToShowDate(comment.createdAt)
                                    }}</strong>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- <button @click.prevent="logUsers">Click me</button> -->
                    <!-- <textarea
            class="order-note-input"
            name="order-note-input"
            id="order-note-input"
            cols="20"
            rows="10"
          >
          </textarea> -->
                    <!-- <div class="enter-input"><i class="fas fa-dot-circle"></i></div> -->
                </div>
            </div>
            <div v-if="this.orderlines">
                <div class="accordion">
                    <div
                        class="accordion-item"
                        v-for="(orderline, index) in orderlines"
                        :key="orderlineKeys[orderline.guid]"
                    >
                        <orderline
                            :orderline="orderline"
                            :index="index"
                            :totalOrderlines="orderlines.length"
                            :isOrderDetail="true"
                            :isProductionDetail="false"
                            v-on:orderlineUpdated="updateOrderline"
                            v-on:orderStatusChanged="updateOrderStatus"
                        />
                    </div>
                </div>
            </div>
            <!-- OrderLines accordion -->
            <!-- <order-line-list :key="this.orderLineListKey" class="row" 
      :orderlines="this.order.orderlines" 
      v-on:fileSubmitted="resetOrderlines" /> -->

            <!-- <div class="col-6 col-lg-6 col-xl-4 pt-5">
        <div v-if="this.order" class="status-legend">
          <div class="header">
            <h5>Ontvangen</h5>
            <h4>Statussen</h4>
          </div>
          <div class="body">
            <div class="pb-3">
              <h5 class="pb-3">Order</h5>
              <p v-if="this.order.order_date">
                <status-dot :status="'Order geplaatst'" :date="this.order.order_date" />
              </p>
              <p v-if="this.order.payment_ref">
                <status-dot :status="'Betaling ontvangen via Mollie'" />
              </p>
            </div>
            <div v-for="orderline in this.order.orderlines" :key="orderline.id" class="pb-3">
              <div v-for="file in orderline.files" :key="file.id">
                <h5 class="pb-3">{{ orderline.seller_order_line_ref }} Bestanden</h5>
                  <div v-for="status in file.statuses" :key="status.id">
                      <status-dot :status="status.status" /> <span v-if="status.date" class="float-end date">{{ this.parseDate(status.date) }}</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
        </div>
        <div id="order-comment-modal">
            <p class="d-flex justify-content-end">
                <button @click="closeCommentModal" class="btn btn-primary">
                    X
                </button>
            </p>
            <!-- <div id="order-comments" class="">
                <p
                    class="m-0 text-start"
                    v-for="orderComment in orderComments"
                    :key="orderComment.id"
                >
                    {{ orderComment.body }}
                </p>
            </div> -->
            <form
                @submit.prevent="postOrderComment($event)"
                class="h-100 mt-3"
                id="order-comment-form"
            >
                <textarea
                    name="comment"
                    id="comment"
                    placeholder="Schrijf hier je opmerking"
                    v-on:keydown.enter="submitFormByEnter($event, 'order-comment-form')"
                ></textarea>
                <br />
                <button type="submit" class="btn btn-primary">Submit</button>
            </form>
        </div>
        <!-- {{ this.order }} -->
    </div>
</template>

<script>
/*
Were going to make a component for a single order line, each order line compnent will get a key which will link to a value in an
array. The orderline component will get a prop and data that indicates if the accordion is open or not. This will be set to false
(closed) on the first load from the orderdetail page. After that it will be reloaded based on the value it currently has. We have 
to check if these values will be kept if we rerender the component using the key.

steps:
  create a new orderline for an order
  for loop to print each orderlines data to the orderdetail
  create orderlinekey array
  create new orderline component with unique key
    this will be the entire accordion
  create a new get orderline function in the mixins
  emit an signal form the orderline component whenever a file gets updated (or first uploaded)
  listen for the signal on the orderline component in the orderdetails view
  when the signal is emitted run a function rerenderOrderLine(orderlineGuid) in the orderlineview
  this function calls the getOrderLine(orderlineGuid) function from the mixins and updates the orderlines array TEST
    it then increments the orderlinekey  TEST

*/

import axios from "axios";
import StatusDot from "@/components/StatusDot.vue";
import Orderline from "@/components/order/Orderline.vue";
// import OrderLineList from "@/components/order/OrderLineList.vue";

export default {
    name: "OrderDetail",

    components: {
        StatusDot,
        Orderline,
    },

    data() {
        return {
            loaded: false,
            guid: this.$route.params.guid,
            order: {},
            orderlines: [],
            orderlineKeys: {},
            customer: {},
            orderComments: [],
            orderStatus: "Open",
        };
    },

    watch: {
        order: function() {
            this.orderStatus = this.order.status;
        },
    },

    async beforeMount() {
        await this.getOrderByGuid(this.guid);
        this.orderlines = this.order.orderlines;
        this.orderlines.forEach((orderline) => {
            this.orderlineKeys[orderline.guid] = 0;
        });
        await this.getOrderComments(this.order.guid).then((result) => {
            this.orderComments = result.data;
        });
    },

    created() {},

    methods: {
        updateOrderStatus(orderIsClosed) {
            if (orderIsClosed) this.orderStatus = "Closed";
            if (!orderIsClosed) this.orderStatus = "Open";
        },
        async getOrderByGuid(guid) {
            await axios
                .get(`v1/order/${guid}`)
                .then(async (response) => {
                    await this.getCustomerByGuid(
                        response.data.customer_guid
                    ).then((customer) => {
                        this.customer = customer;
                    });
                    response.data.order_date = this.parseDate(
                        response.data.order_date
                    );
                    this.order = response.data;

                    this.loaded = true;
                })
                .catch((err) => {
                    this.loaded = false;
                    this.errorModal(err);
                });
        },
        async resetOrderlines(openOrderLines, currentScroll) {
            await this.getOrderByGuid(this.guid);
            this.orderLineListKey++;
            openOrderLines.forEach((element) => {
                element.click();
            });
            document.documentElement.scrollTop = currentScroll;
        },
        async showCommentModal() {
            this.activateBlockOut();
            document.getElementById("order-comment-modal").style.display =
                "block";
        },
        closeCommentModal() {
            document.getElementById("order-comment-modal").style.display =
                "none";
            this.deactivateBlockOut();
        },
        async postOrderComment(event) {
            event.preventDefault();
            const formData = new FormData(event.target);
            const postData = {};
            for (let value of formData.entries()) {
                postData["body"] = value[1];
            }
            postData["orderGuid"] = this.order.guid;
            postData["userEmail"] = this.$auth.user.value.email;
            await this.createOrderComment(postData)
                .then((result) => {
                    this.orderComments.unshift(result.data);
                    this.deactivateBlockOut();
                    this.closeCommentModal();
                })
                .catch((e) => console.log(e));
        },
        submitFormByEnter(event, formId) {
            event.preventDefault();
            document
                .getElementById(formId)
                .dispatchEvent(
                    new Event("submit", { cancelable: true, bubbles: true })
                );
        },
    },
};
</script>

<style>
.information-top {
    background-color: #f0f0f0;
    text-align: left;
    position: relative;
}
.information-top > div {
    padding: 0.5rem;
    /* flex: 1; */
}
.order-info > div {
    padding: 0.5rem;
    /* flex: 1; */
}
.order-info textarea {
    width: 100%;
    height: 150px;
    background-color: lightgray;
    resize: none;
}

.information-top .info-title {
    text-transform: uppercase;
    color: #858585;
    margin: 0;
}

.information-top .info-value {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
}

.information-top i {
    color: #fff;
    background-color: #222;
    padding: 10px 15px !important;
    border-radius: 2px;
}
.info-back {
    position: relative;
    top: -20px;
}
.info-back i {
    cursor: pointer;
    color: #fff;
    background-color: #222;
    padding: 10px 15px !important;
    border-radius: 2px;
}

.status-dot {
    height: 15px;
    width: 15px;
    background-color: #d6d5d5;
    border-radius: 50%;
    display: inline-block;
    margin-right: 1rem;
}

.vl {
    border: grey 1px solid;
    background-color: grey;
    position: absolute;
    width: 1px !important;
    height: 60%;
    left: 62.5%;
    padding: 0 !important;
    margin: 0 !important;
}

.order-info {
    margin: 0.5rem 0;
}

.order-info h4 {
    font-weight: bold;
}

.order-info p {
    margin: 0;
}

.status-legend {
    background-color: #222;
    color: #fff;
    text-align: left;
    border-radius: 3px;
}

.status-legend h5 {
    text-transform: uppercase;
    color: #858585;
    font-size: 1rem;
    margin: 0;
}

.status-legend h4 {
    font-weight: bold;
    margin: 0;
}
.status-legend .header {
    background-color: #131315;
    padding: 2rem;
    border-radius: 3px;
}

.status-legend .body {
    padding: 2rem;
    font-size: 0.9rem;
}
.note-form {
    position: relative;
}
.enter-input {
    position: absolute;
    bottom: 1em;
    right: 1em;
    cursor: pointer;
}
.accordion-item {
    margin-top: 1em;
    border: 0px !important;
}
.comment-table {
    max-height: 15em;
    overflow: auto;
}
.comment-table:hover {
    box-shadow: lightgray 0 0 10px;
}
.comment-table::-webkit-scrollbar {
    width: 10px;
}
.comment-table::-webkit-scrollbar-track {
    /* background: white; */
    background: #f1f1f1;
}
.comment-table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}
.comment-table::-webkit-scrollbar-thumb:hover {
    background: #555;
}
#order-comment-modal {
    z-index: 1100;
    display: none;
    width: 50em;
    position: fixed;
    top: 20vh;
    left: 37vw;
    background-color: white;
    min-height: 35vh;
    height: auto;
    padding: 1em;
    border-radius: 10px;
    box-shadow: lightgray 0 0 10px;
}
#order-comment-modal textarea {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 70%;
}
#order-comment-modal p button {
    border-top-right-radius: 10px !important;
}
</style>
